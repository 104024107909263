<template>
  <v-container fluid class="my-3 mx-0 pa-0">
    <p class="mt-5 mb-8 mx-2" style="font-size: 20px">
      {{ $t("history.elements.contacts.internalContacts") }}
    </p>
    <v-card
      elevation="0"
      min-height="50vh"
      class="d-flex flex-column align-center"
    >
      <v-data-table
        style="width: 100%"
        :headers="translatedHeaders"
        :items="filteredContacts"
        :search="search"
        hide-default-footer
        mobile-breakpoint="768"
      >
        <template v-slot:[`item.email`]="{ item }">
          <span style="color: #2a58e2">
            {{ item.email }}
          </span>
        </template>
      </v-data-table>
      <div
        v-if="$store.getters.getUser.role != 'company-user'"
        class="main-container"
        @click="openModal"
      >
        <div
          class="mr-3 d-inline-flex align-center justify-center"
          style="
            height: 30px;
            width: 30px;
            background-color: #ffa726;
            border-radius: 50%;
          "
        >
          <v-icon dark>mdi-plus</v-icon>
        </div>
        <p class="ma-0">
          {{ $t("history.elements.contacts.addMoreContacts") }}
        </p>
      </div>
    </v-card>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("history.elements.contacts.manageContacts")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-list>
              <v-list-item-group v-model="selectedContacts" multiple>
                <v-list-item
                  v-for="item in localContacts"
                  :key="contactKey(item)"
                >
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="selectedContacts.includes(contactKey(item))"
                      @change="toggleContact(item)"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.job_title
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal">{{
            $t("history.elements.contacts.cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveContacts">{{
            $t("history.elements.contacts.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import team from "@/api/team";
export default {
  name: "Contacts",
  data() {
    return {
      search: "",
      dialog: false,
      localContacts: [],
      selectedContacts: [],
      headers: [
        { text: "Name", value: "name", width: "20%", sortable: false },
        { text: "Job", value: "job_title", width: "20%", sortable: false },
        { text: "E-mail", value: "email", width: "40%", sortable: false },
        { text: "Phone", value: "phone", width: "20%", sortable: false },
      ],
    };
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    contactKey(contact) {
      return `${contact.name}-${contact.email}`;
    },
    openModal() {
      this.dialog = true;
      this.localContacts = JSON.parse(JSON.stringify(this.contacts));
      this.selectedContacts = this.localContacts
        .filter((contact) => contact.is_contact)
        .map((contact) => this.contactKey(contact));
    },
    closeModal() {
      this.dialog = false;
    },
    toggleContact(contact) {
      const key = this.contactKey(contact);
      const index = this.selectedContacts.indexOf(key);
      if (index > -1) {
        this.selectedContacts.splice(index, 1);
      } else {
        this.selectedContacts.push(key);
      }

      const localContact = this.localContacts.find(
        (c) => this.contactKey(c) === key,
      );
      if (localContact) {
        localContact.is_contact = !localContact.is_contact;
      }
    },
    async saveContacts() {
      const data = {
        project_id: this.$route.params.id,
        contacts: this.localContacts,
      };

      try {
        const response = await team.updateContact(data);
        if (response.code == 200) {
          this.$emit("contacts-updated", this.localContacts);
          this.closeModal();
        } else {
          console.error("Failed to update contacts:", response);
        }
      } catch (error) {
        console.error("Failed to update contacts:", error);
      }
    },
  },
  computed: {
    translatedHeaders() {
      return this.headers.map((header) => ({
        ...header,
        text: this.$t(`history.elements.contacts.headers.${header.value}`),
      }));
    },
    filteredContacts() {
      return this.localContacts.filter((contact) => contact.is_contact);
    },
  },
  watch: {
    contacts: {
      immediate: true,
      handler(newValue) {
        this.localContacts = [...newValue];
      },
    },
  },
};
</script>

<style scoped>
::v-deep table tbody tr td {
  padding: 20px 16px !important;
}

@media (min-width: 768px) {
  ::v-deep table tbody tr td:first-child,
  ::v-deep table thead tr th:first-child {
    padding-left: 3rem !important;
  }
  ::v-deep table tbody tr td:last-child,
  ::v-deep table thead tr th:last-child {
    padding-right: 3rem !important;
  }

  ::v-deep table tbody tr td {
    border: none !important;
  }
}

::v-deep table thead tr th {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 15px;
  border: 1px solid #b5c0cc;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.main-container:hover {
  background-color: #f5f5f5;
}

@media (min-width: 768px) {
  .main-container {
    width: 35%;
  }
}
</style>
