import config from "./index";

async function createProjectScope(projectId) {
  const response = await fetch(`${config.apiUrl}/v1/project/scope`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify({ project_id: projectId }),
  });
  const data = await response.json();
  return data;
}

async function getProjectScope(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/scope?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function createStructure(structure) {
  const response = await fetch(`${config.apiUrl}/v1/project/scope/structure`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(structure),
  });
  const data = await response.json();
  return data;
}

async function getStructure(structureId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/scope/structure?structure_id=${structureId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function deleteStructure(structureId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/scope/structure?structure_id=${structureId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function createStructureTrade(trade) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/scope/structure/trade`,
    {
      method: "POST",
      headers: {
        "Authentication-Token": config.getToken(),
      },
      body: trade,
    },
  );
  const data = await response.json();
  return data;
}

async function getStructureTrade(tradeId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/scope/structure/trade?trade_id=${tradeId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

export default {
  createProjectScope,
  getProjectScope,
  createStructure,
  getStructure,
  deleteStructure,
  createStructureTrade,
  getStructureTrade,
};
