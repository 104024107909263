<template>
  <v-container fluid style="height: 100%">
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-height="98%"
      min-width="95%"
      elevation="0"
    >
      <header class="d-flex flex-column mt-5 mb-10">
        <p class="mb-2 text-center" style="font-size: 20px">
          {{ $t("projects.cards.customerInformation.newSubmission") }}
        </p>
        <p class="text-center font-weight-regular text-h4">
          {{ $t("projects.cards.customerInformation.customerInformation") }}
        </p>
      </header>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              :rules="nameRules"
              :label="$t('projects.cards.customerInformation.customerName')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-8 text-h6 font-weight-regular">
            {{ $t("projects.cards.customerInformation.lossAddress") }}
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address"
              :rules="addressRules"
              :label="$t('projects.cards.customerInformation.address')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address2"
              :label="$t('projects.cards.customerInformation.addressLine2')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="city"
              :rules="cityRules"
              :label="$t('projects.cards.customerInformation.city')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="state"
              :rules="stateRules"
              :label="$t('projects.cards.customerInformation.state')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="zip"
              :rules="zipRules"
              :label="$t('projects.cards.customerInformation.zipCode')"
              type="number"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-2 text-h6 font-weight-regular">
            {{ $t("projects.cards.customerInformation.insuredMailingAddress") }}
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="copyAddress"
              :label="$t('projects.cards.customerInformation.useLossAddress')"
              @change="copyAddressInfo"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="addressMail"
              :rules="addressRules"
              :label="$t('projects.cards.customerInformation.address')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address2Mail"
              :label="$t('projects.cards.customerInformation.addressLine2')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="cityMail"
              :rules="cityRules"
              :label="$t('projects.cards.customerInformation.city')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="stateMail"
              :rules="stateRules"
              :label="$t('projects.cards.customerInformation.state')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="zipMail"
              :rules="zipRules"
              :label="$t('projects.cards.customerInformation.zipCode')"
              type="number"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-8 text-h6 font-weight-regular">
            {{ $t("projects.cards.customerInformation.contactInfo") }}
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$t('projects.cards.customerInformation.customerEmail')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="phone"
              :rules="phoneRules"
              :label="$t('projects.cards.customerInformation.customerPhone')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="insuredName"
              :label="
                $t('projects.cards.customerInformation.additionalInsuredName')
              "
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="insuranceCompany"
              :label="$t('projects.cards.customerInformation.insuranceCompany')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="policyNumber"
              :label="$t('projects.cards.customerInformation.policyNumber')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="claimNumber"
              :label="$t('projects.cards.customerInformation.claimNumber')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :label="$t('projects.cards.customerInformation.causeOfLoss')"
              :items="causeOfLossOptions"
              outlined
              v-model="causeOfLoss"
            ></v-select>
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="6">
            <v-select
              :label="$t('projects.cards.customerInformation.claimInspected')"
              :items="booleanOptions"
              outlined
              v-model="claimInspected"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :label="$t('projects.cards.customerInformation.claimReported')"
              :items="booleanOptions"
              outlined
              v-model="claimApproved"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('projects.cards.customerInformation.dateOfLoss')"
              outlined
              v-model="dateOfLoss"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :label="
                $t('projects.cards.customerInformation.currentClaimStatus')
              "
              :items="claimStatusOptions"
              outlined
              v-model="ClaimSatus"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              :label="$t('projects.cards.customerInformation.stateOfEmergency')"
              :items="stateOfEmergencyOptions"
              outlined
              v-model="stateOfEmergency"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :label="$t('projects.cards.customerInformation.hasMortgage')"
              :items="booleanOptions"
              outlined
              v-model="hasMortgage"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="mortgageCompanyName"
              :label="$t('projects.cards.customerInformation.mortgageName')"
              outlined
              :disabled="hasMortgage == 'No'"
              :rules="mortgageRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              :label="$t('projects.cards.customerInformation.companyCamLinks')"
              outlined
              v-model="companyCam"
              rows="3"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              :label="
                $t('projects.cards.customerInformation.descriptionOfLoss')
              "
              outlined
              v-model="claimNotes"
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>
        <div
          class="d-flex justify-center mb-5"
          style="width: 100%; gap: 0.5rem"
        >
          <v-btn @click="goBack" class="edit-button mt-10">
            {{ $t("projects.cards.customerInformation.backToOptions") }}
          </v-btn>
          <v-btn
            @click="updateCustomer"
            :disabled="!valid"
            class="edit-button mt-10"
          >
            {{ $t("projects.cards.customerInformation.confirmInformation") }}
          </v-btn>
        </div>
      </v-form>
      <v-alert v-if="error" type="error" dense text>
        {{ $t("projects.cards.customerInformation.errorOccurred") }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import project from "@/api/project";
export default {
  name: "CustomerInformation",
  data() {
    return {
      valid: true,
      error: false,
      copyAddress: true,
      name: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      addressMail: "",
      address2Mail: "",
      cityMail: "",
      stateMail: "",
      zipMail: "",
      email: "",
      phone: "",
      insuredName: "",
      insuranceCompany: "",
      policyNumber: "",
      claimNumber: "",
      causeOfLoss: "Wind",
      claimInspected: "Yes",
      claimApproved: "Yes",
      dateOfLoss: "",
      ClaimSatus: "Open",
      stateOfEmergency: "State of Emergency (Hurricane 1 year ago or less)",
      hasMortgage: "Yes",
      mortgageCompanyName: "",
      companyCam: "",
      claimNotes: "",
      nameRules: [
        (v) =>
          !!v ||
          this.$t("projects.cards.customerInformation.customerNameRequired"),
        (v) =>
          (v && v.length >= 4) ||
          this.$t("projects.cards.customerInformation.customerNameMinLength"),
      ],
      addressRules: [
        (v) =>
          !!v || this.$t("projects.cards.customerInformation.addressRequired"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("projects.cards.customerInformation.addressMinLength"),
      ],
      cityRules: [
        (v) =>
          !!v || this.$t("projects.cards.customerInformation.cityRequired"),
        (v) =>
          (v && v.length >= 4) ||
          this.$t("projects.cards.customerInformation.cityMinLength"),
      ],
      stateRules: [
        (v) =>
          !!v || this.$t("projects.cards.customerInformation.stateRequired"),
        (v) =>
          (v && v.length >= 2) ||
          this.$t("projects.cards.customerInformation.stateMinLength"),
      ],
      zipRules: [
        (v) => !!v || this.$t("projects.cards.customerInformation.zipRequired"),
        (v) =>
          /^\d+$/.test(v) ||
          this.$t("projects.cards.customerInformation.zipNumber"),
      ],
      emailRules: [
        (v) =>
          !!v || this.$t("projects.cards.customerInformation.emailRequired"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t("projects.cards.customerInformation.emailValid"),
      ],
      phoneRules: [
        (v) =>
          !!v || this.$t("projects.cards.customerInformation.phoneRequired"),
        (v) =>
          (v && v.length >= 10) ||
          this.$t("projects.cards.customerInformation.phoneMinLength"),
      ],
      mortgageRules: [
        (v) =>
          this.hasMortgage !== "Yes" ||
          (v && v.length >= 5) ||
          this.$t("projects.cards.customerInformation.mortgageNameMinLength"),
      ],
      causeOfLossOptions: [],
      claimStatusOptions: [],
      stateOfEmergencyOptions: [],
      booleanOptions: [],
    };
  },
  methods: {
    ...mapMutations(["setCustomerUpload"]),
    goBack() {
      this.$refs.form.reset();
      this.copyAddress = true;
      this.$router.go(-1);
    },
    async updateCustomer() {
      if (this.$refs.form.validate()) {
        this.valid = false;
        this.error = false;
        try {
          const customerData = {
            project_id: this.$route.params.id,
            name: this.name,
            address: this.address,
            address_line: this.address2,
            city: this.city,
            state: this.state,
            zip_code: this.zip,
            insured_address: this.addressMail,
            insured_address_line: this.address2Mail,
            insured_city: this.cityMail,
            insured_state: this.stateMail,
            insured_zip_code: this.zipMail,
            customer_email_address: this.email,
            customer_phone_number: this.phone,
            additional_insured_name: this.insuredName,
            insurance_company: this.insuranceCompany,
            policy_number: this.policyNumber,
            claim_number: this.claimNumber,
            cause_of_loss: this.causeOfLoss,
            inspected_by_insurance_company: this.claimInspected == "Yes",
            reported_to_insurance_company: this.claimApproved == "Yes",
            date_of_loss_or_storm: this.dateOfLoss,
            claim_status: this.ClaimSatus,
            has_mortgage: this.hasMortgage == "Yes",
            mortgage_company: this.mortgageCompanyName,
            state_of_emergency:
              this.stateOfEmergency ==
              "State of Emergency (Hurricane 1 year ago or less)",
            companycam: this.companyCam,
            claim_notes: this.claimNotes,
          };

          const response = await project.createCustomer(customerData);
          if (response.code == 200) {
            this.valid = true;
            this.setCustomerUpload(true);
            this.goBack();
          } else {
            this.valid = true;
            this.error = true;
          }
        } catch (error) {
          this.valid = true;
          this.error = true;
        }
      }
    },
    copyAddressInfo() {
      if (this.copyAddress) {
        this.addressMail = this.address;
        this.address2Mail = this.address2;
        this.cityMail = this.city;
        this.stateMail = this.state;
        this.zipMail = this.zip;
      } else {
        this.addressMail = "";
        this.address2Mail = "";
        this.cityMail = "";
        this.stateMail = "";
        this.zipMail = "";
      }
    },
    setSelectOptions() {
      this.causeOfLossOptions = [
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.hail",
          ),
          value: "Hail",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.wind",
          ),
          value: "Wind",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.hailWind",
          ),
          value: "Hail & Wind",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.hurricaneWind",
          ),
          value: "Hurricane Wind",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.hurricaneFlood",
          ),
          value: "Hurricane Flood",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.flood",
          ),
          value: "Flood",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.fire",
          ),
          value: "Fire",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.smoke",
          ),
          value: "Smoke",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.fireSmoke",
          ),
          value: "Fire & Smoke",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.fallenTree",
          ),
          value: "Fallen Tree",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.lightning",
          ),
          value: "Lightning",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.tornado",
          ),
          value: "Tornado",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.vandalism",
          ),
          value: "Vandalism",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.marine",
          ),
          value: "Marine",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.water",
          ),
          value: "Water",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.causeOfLossOptions.other",
          ),
          value: "Other",
        },
      ];
      this.claimStatusOptions = [
        {
          text: this.$t(
            "projects.cards.customerInformation.claimStatusOptions.new",
          ),
          value: "New",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.claimStatusOptions.reopen",
          ),
          value: "Reopen",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.claimStatusOptions.denied",
          ),
          value: "Denied",
        },
      ];
      this.stateOfEmergencyOptions = [
        {
          text: this.$t(
            "projects.cards.customerInformation.stateOfEmergencyOptions.stateOfEmergency",
          ),
          value: "State of Emergency (Hurricane 1 year ago or less)",
        },
        {
          text: this.$t(
            "projects.cards.customerInformation.stateOfEmergencyOptions.nonEmergency",
          ),
          value: "Non-Emergency",
        },
      ];
      this.booleanOptions = [
        {
          text: this.$t(
            "projects.cards.customerInformation.booleanOptions.yes",
          ),
          value: "Yes",
        },
        {
          text: this.$t("projects.cards.customerInformation.booleanOptions.no"),
          value: "No",
        },
      ];
    },
  },
  watch: {
    "$i18n.locale": function () {
      this.setSelectOptions();
    },
    hasMortgage: function () {
      if (this.hasMortgage == "No") {
        this.mortgageCompanyName = "";
        this.$refs.form.resetValidation("mortgageCompanyName");
      }
    },
  },
  mounted() {
    this.setSelectOptions();
  },
  async activated() {
    const response = await project.fetchCustomer(this.$route.params.id);
    if (response.code == 200) {
      const info = response.data.customer_information;
      const date = new Date(info.date_of_loss_or_storm);
      let formattedDate = date.toISOString().split("T")[0];
      this.name = info.name || "";
      this.address = info.address || "";
      this.address2 = info.address_line || "";
      this.city = info.city || "";
      this.state = info.state || "";
      this.zip = info.zip_code || "";
      this.addressMail = info.insured_address || "";
      this.address2Mail = info.insured_address_line || "";
      this.cityMail = info.insured_city || "";
      this.stateMail = info.insured_state || "";
      this.zipMail = info.insured_zip_code || "";
      this.email = info.customer_email_address || "";
      this.phone = info.customer_phone_number || "";
      this.insuredName = info.additional_insured_name || "";
      this.insuranceCompany = info.insurance_company || "";
      this.policyNumber = info.policy_number || "";
      this.claimNumber = info.claim_number || "";
      this.causeOfLoss = info.cause_of_loss || "";
      this.claimInspected = info.inspected_by_insurance_company ? "Yes" : "No";
      this.dateOfLoss = formattedDate;
      this.claimApproved = info.reported_to_insurance_company ? "Yes" : "No";
      this.ClaimSatus = info.claim_status || "";
      this.hasMortgage = info.has_mortgage ? "Yes" : "No";
      this.mortgageCompanyName = info.mortgage_company || "";
      this.stateOfEmergency = info.state_of_emergency
        ? "State of Emergency (Hurricane 1 year ago or less)"
        : "Non-Emergency";
      this.companyCam = info.companycam || "";
      this.claimNotes = info.claim_notes || "";
    }
    if (this.previousRoute === "NewProject") {
      this.copyAddressInfo();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "HistoryFullView") {
      this.setCustomerUpload(false);
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from.name;
    });
  },
};
</script>

<style scoped>
header,
form {
  width: 95%;
}

@media (min-width: 768px) {
  header,
  form {
    width: 90%;
  }
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  min-width: 175px !important;
  max-width: 48% !important;
  font-size: 15px !important;
  font-family: sans-serif !important;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}

.map {
  background-color: #ccc;
  border-radius: 15px;
  margin-bottom: 30px;
  aspect-ratio: 4/3;
  padding: 50px;
}
</style>
