<template>
  <v-container fluid class="my-5 mx-0 pa-0">
    <v-card style="width: 100%" class="pa-6" elevation="0" min-height="50vh">
      <div
        v-for="(activity, index) in activities"
        :key="index"
        class="activity-content"
      >
        <div class="d-flex justify-center align-center">
          <v-img
            max-height="90"
            max-width="90"
            :src="getImagePath(activity.event)"
          ></v-img>
          <p class="my-0 mx-2">
            {{ activity.description }}
          </p>
        </div>
        <div class="text-body-2 color-disabled">
          {{ formatDate(activity.date) }}
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import fullProject from "@/api/fullProject";
export default {
  name: "TimeLine",
  data() {
    return {
      activities: [],
    };
  },
  methods: {
    async getActivities() {
      try {
        const response = await fullProject.fetchActivity(this.$route.params.id);
        if (response.code === 200) this.activities = response.data.activity;
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const formattedDate = date.format("hh:mm A - MM / DD / YYYY");
      return formattedDate;
    },
    getImagePath(event) {
      const eventToImagePath = {
        "Claim Created": require("@/assets/dashboard/claim.svg"),
        "File Uploaded": require("@/assets/dashboard/files.svg"),
        "Contacts Updated": require("@/assets/dashboard/workers.svg"),
        Comments: require("@/assets/dashboard/comment.svg"),
        Payment: require("@/assets/dashboard/payment.svg"),
      };
      return eventToImagePath[event] || require("@/assets/dashboard/claim.svg");
    },
  },
  async mounted() {
    await this.getActivities();
  },
};
</script>

<style scoped>
.activity-content {
  border: 1px solid #b5c0cc;
  border-radius: 12px;
  height: 100px;
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.activity-content:not(:first-child)::before {
  content: "";
  position: absolute;
  top: -1.5rem;
  left: 50%;
  width: 1px;
  height: 1.5rem;
  background-color: #b5c0cc;
}

.color-disabled {
  color: #a09d9d;
}
</style>
