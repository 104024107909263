<template>
  <v-container fluid style="height: 100%">
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-height="98%"
      min-width="95%"
      elevation="0"
    >
      <header class="d-flex flex-column mt-5 mb-10">
        <p class="mb-12 text-center" style="font-size: 20px">
          {{ $t("projects.newProjectChargeData.newSubmission") }}
        </p>
        <p class="text-center font-weight-regular text-h4">
          {{ $t("projects.newProjectChargeData.createNewFile") }}
        </p>
      </header>
      <v-row class="card-container">
        <v-col cols="12" md="6" lg="3">
          <v-card
            class="pa-5 fill-height"
            elevation="0"
            :class="{ 'blue-background': customerUpload }"
            style="border: 1px solid #ccc"
          >
            <v-card-title class="d-flex flex-column text-center">
              <v-img src="@/assets/cards/information.svg"></v-img>
              <span :class="{ 'blue-text': customerUpload }">
                {{ $t("projects.newProjectChargeData.customer") }}
              </span>
              <span :class="{ 'blue-text': customerUpload }">
                {{ $t("projects.newProjectChargeData.information") }}
              </span>
            </v-card-title>

            <v-card-text
              class="text-center mt-5 mb-10"
              :class="{ 'blue-text-principal': customerUpload }"
            >
              {{ $t("projects.newProjectChargeData.customerDescription") }}
            </v-card-text>

            <v-card-actions>
              <v-btn class="edit-button" block @click="goToCustomer">
                {{ $t("projects.newProjectChargeData.uploadNow") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-card
            class="pa-5 fill-height"
            elevation="0"
            :class="{ 'blue-background': externalFiles }"
            style="border: 1px solid #ccc"
          >
            <v-card-title class="d-flex flex-column text-center">
              <v-img src="@/assets/cards/file.svg"></v-img>
              <span :class="{ 'blue-text': externalFiles }">
                {{ $t("projects.newProjectChargeData.external.top") }}
              </span>
              <span :class="{ 'blue-text': externalFiles }">
                {{ $t("projects.newProjectChargeData.external.bottom") }}
              </span>
            </v-card-title>

            <v-card-text
              class="text-center mt-5 mb-10"
              :class="{ 'blue-text-principal': externalFiles }"
            >
              {{ $t("projects.newProjectChargeData.external.description") }}
            </v-card-text>

            <v-card-actions>
              <v-btn @click="goToExternalFiles" class="edit-button" block>
                {{ $t("projects.newProjectChargeData.uploadNow") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-card
            class="pa-5 fill-height"
            elevation="0"
            :class="{ 'blue-background': internalFiles }"
            style="border: 1px solid #ccc"
          >
            <v-card-title class="d-flex flex-column text-center">
              <v-img src="@/assets/cards/file.svg"></v-img>
              <span :class="{ 'blue-text': internalFiles }">
                {{ $t("projects.newProjectChargeData.internal.top") }}
              </span>
              <span :class="{ 'blue-text': internalFiles }">
                {{ $t("projects.newProjectChargeData.internal.bottom") }}
              </span>
            </v-card-title>

            <v-card-text
              class="text-center mt-5 mb-10"
              :class="{ 'blue-text-principal': internalFiles }"
            >
              {{ $t("projects.newProjectChargeData.internal.description") }}
            </v-card-text>

            <v-card-actions>
              <v-btn @click="goToInternalFiles" class="edit-button" block>
                {{ $t("projects.newProjectChargeData.uploadNow") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-card
            class="pa-5 fill-height"
            elevation="0"
            :class="{ 'blue-background': documentsUpload }"
            style="border: 1px solid #ccc"
          >
            <v-card-title class="d-flex flex-column text-center">
              <v-img src="@/assets/cards/documents.svg"></v-img>
              <span :class="{ 'blue-text': documentsUpload }">
                {{ $t("projects.newProjectChargeData.project.top") }}
              </span>
              <span :class="{ 'blue-text': documentsUpload }">
                {{ $t("projects.newProjectChargeData.project.bottom") }}
              </span>
            </v-card-title>

            <v-card-text
              class="text-center mt-5 mb-10"
              :class="{ 'blue-text-principal': documentsUpload }"
            >
              {{ $t("projects.newProjectChargeData.project.description") }}
            </v-card-text>

            <v-card-actions>
              <v-btn @click="goToDocuments" class="edit-button" block>
                {{ $t("projects.newProjectChargeData.uploadNow") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <footer>
        <v-btn
          class="edit-button"
          v-if="!alert.show"
          @click="alert.show = true"
          block
          :disabled="!customerUpload"
        >
          {{ $t("projects.newProjectChargeData.submitClaim") }}
        </v-btn>
        <v-btn v-else class="edit-button" @click="goToDashboard" block>
          {{ $t("projects.newProjectChargeData.goToDashboard") }}
        </v-btn>
        <v-alert class="my-5" v-if="alert.show" :type="alert.type" dense text>
          {{ $t("projects.newProjectChargeData.claimSubmitted") }}
        </v-alert>
        <p class="text-center mt-15" v-if="!alert.show">
          {{ $t("projects.newProjectChargeData.completeLater") }}
        </p>
      </footer>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
export default {
  name: "NewProjectChargeData",
  data() {
    return {
      alert: {
        show: false,
        type: "success",
      },
    };
  },
  computed: {
    ...mapGetters(["getNewProject"]),
    customerUpload() {
      return this.getNewProject.customerUpload;
    },
    scopeUpload() {
      return this.getNewProject.scopeUpload;
    },
    externalFiles() {
      return this.getNewProject.externalFiles;
    },
    internalFiles() {
      return this.getNewProject.internalFiles;
    },
    documentsUpload() {
      return this.getNewProject.documentsUpload;
    },
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations(["resetNewProject", "setScopeUpload"]),
    goToCustomer() {
      this.$router.push({
        name: "CustomerInformation",
        params: { id: this.projectData.id },
      });
    },
    goToScope() {
      this.setScopeUpload(true);
      this.$router.push({
        name: "ScopeNewProject",
        params: { id: this.projectData.id },
      });
    },
    goToExternalFiles() {
      this.$router.push({
        name: "FileNewProject",
        params: { id: this.projectData.id },
      });
    },
    goToInternalFiles() {
      this.$router.push({
        name: "FileInternalNewProject",
        params: { id: this.projectData.id },
      });
    },
    goToDocuments() {
      this.$router.push({
        name: "DocumentsNewProject",
        params: { id: this.projectData.id },
      });
    },
    goToDashboard() {
      this.resetNewProject();
      this.$emit("completedProject");
      this.alert.show = false;
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style scoped>
header {
  width: 90%;
}

footer {
  width: 70%;
  margin-top: 40px;
  margin-bottom: 60px;
}

.card-container {
  width: 80%;
}

.row {
  flex: 0 1 auto;
}

@media (min-width: 992px) {
  header {
    width: 40%;
  }

  footer {
    width: 35%;
  }
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  width: 90% !important;
}

.card-container .v-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-container .v-card-title,
.card-container .v-card-text,
.card-container .v-card-actions {
  flex: 0 0 auto; /* No permite que estos hijos crezcan o se encojan */
}

.card-container .v-card-text {
  flex-grow: 1; /* Permite que el texto de la tarjeta crezca para llenar el espacio disponible */
}

.blue-background {
  background-color: #edf2fd;
}

.blue-text {
  color: #2b58e2 !important;
}

.blue-text-principal {
  color: #7290ec !important;
}
</style>
