import config from "./index";

async function createProject(project) {
  const response = await fetch(`${config.apiUrl}/v1/project`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(project),
  });
  const data = await response.json();
  return data;
}

async function fetchProject(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function updateProject(project) {
  const response = await fetch(`${config.apiUrl}/v1/project`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(project),
  });
  const data = await response.json();
  return data;
}

async function createCustomer(customer) {
  const response = await fetch(`${config.apiUrl}/v1/project/customer`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(customer),
  });
  const data = await response.json();
  return data;
}

async function fetchCustomer(customerId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/customer?project_id=${customerId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function uploadDocuments(formData) {
  const response = await fetch(`${config.apiUrl}/v1/project/documents`, {
    method: "POST",
    headers: {
      "Authentication-Token": config.getToken(),
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

async function fetchDocuments(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/documents?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function uploadFiles(formData) {
  const response = await fetch(`${config.apiUrl}/v1/project/files`, {
    method: "POST",
    headers: {
      "Authentication-Token": config.getToken(),
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

async function fetchFiles(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/files?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function deleteFile(file) {
  const response = await fetch(`${config.apiUrl}/v1/project/files`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(file),
  });
  const data = await response.json();
  return data;
}

async function editNameFile(file) {
  const response = await fetch(`${config.apiUrl}/v1/project/file/name`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(file),
  });
  const data = await response.json();
  return data;
}

async function createFolder(formData) {
  const response = await fetch(`${config.apiUrl}/v1/project/files`, {
    method: "PUT",
    headers: {
      "Authentication-Token": config.getToken(),
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

export default {
  createProject,
  fetchProject,
  updateProject,
  createCustomer,
  fetchCustomer,
  uploadDocuments,
  fetchDocuments,
  uploadFiles,
  fetchFiles,
  deleteFile,
  editNameFile,
  createFolder,
};
