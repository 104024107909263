<template>
  <v-container fluid class="my-3 mx-0 pa-0">
    <p class="mt-5 mb-8 mx-2" style="font-size: 20px">
      {{ $t("history.elements.forms.lor") }}
    </p>
    <v-card class="card-forms" elevation="0" min-height="30vh">
      <div>
        <v-row v-if="Object.keys(lors).length" class="py-5 px-10">
          <v-col
            v-for="(entry, index) in Object.entries(lors)"
            :key="index"
            class="elements"
            cols="12"
          >
            <span>{{
              $t(`history.elements.forms.${entry[0].replace(/\s/g, "")}`)
            }}</span>
            <span>{{
              ["Sent", "Signed", "Unsigned"].includes(entry[1])
                ? $t(`history.elements.forms.${entry[1]}`)
                : entry[1]
            }}</span>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="!(lors.status == 'Signed')"
        class="d-flex flex-column justify-center align-center mb-5"
        style="width: 90%"
      >
        <p v-if="!Object.keys(lors).length">
          {{ $t("history.elements.forms.noLOR") }}
        </p>
        <v-btn
          v-else
          :disabled="inProgress"
          block
          class="edit-button"
          elevation="0"
          @click="sendLor"
          >{{ $t("history.elements.forms.resend") }}</v-btn
        >
      </div>
      <v-alert class="mt-3 mx-10" v-if="error" type="error" dense text>
        {{ $t("history.elements.forms.error") }}
      </v-alert>
      <v-alert class="mt-3 mx-10" v-if="itsOk" type="success" dense text>
        {{ $t("history.elements.forms.success") }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import fullProject from "@/api/fullProject";
import moment from "moment";
export default {
  name: "Forms",
  data() {
    return {
      error: false,
      itsOk: false,
      inProgress: false,
      lors: {},
    };
  },
  methods: {
    async sendLor() {
      this.inProgress = true;
      this.error = false;
      this.itsOk = false;
      const data = {
        project_id: this.$route.params.id,
      };
      try {
        const response = await fullProject.updateLOR(data);
        if (response.code == 200) {
          this.itsOk = true;
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      }
      this.inProgress = false;
    },
    async getLOR() {
      try {
        const response = await fullProject.fetchLOR(this.$route.params.id);
        if (response.code == 200 && response.data) {
          let letter = response.data.letter;
          if (letter.email_send_date)
            letter.email_send_date = this.formatDate(letter.email_send_date);
          if (letter.reminder_sent_date)
            letter.reminder_sent_date = this.formatDate(
              letter.reminder_sent_date,
            );

          delete letter.project_id;
          this.lors = letter;
        }
      } catch (error) {
        this.$router.push({ name: "History" });
      }
    },
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const dateUpdated = date.format("MM / DD / YYYY HH:mm");
      return dateUpdated;
    },

    capitalize(text) {
      if (!text) return "";
      text = text.replace(/_/g, " "); // Reemplaza todos los guiones bajos con espacios en blanco
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
  },
  async created() {
    await this.getLOR();
  },
};
</script>

<style scoped>
.card-forms {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 20px;
}

@media (min-width: 768px) {
  .card-forms {
    width: 50%;
  }
}

.elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fbfbfb;
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  margin-bottom: 12px;
  height: 40px;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 35px !important;
}
</style>
