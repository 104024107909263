<template>
  <v-app style="background-color: #f5f5f5">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="locale-dropdown-wrapper">
      <v-menu offset-y transition="slide-y-transition" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            color="#2b58e2"
            dark
            v-bind="attrs"
            v-on="on"
            class="locale-dropdown-btn"
          >
            <v-icon left>mdi-translate</v-icon>
            {{ $i18n.locale.toUpperCase() }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="changeLocale('en')">
            <v-list-item-title>English</v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeLocale('es')">
            <v-list-item-title>Español</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style>
.locale-dropdown-wrapper {
  position: fixed;
  top: 20px;
  right: 35px;
  z-index: 1000;
}

.locale-dropdown-btn {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ddd;
}

.v-menu__content {
  min-width: auto !important;
}
</style>
