<template>
  <v-container fluid style="height: 100%">
    <v-card
      class="d-flex flex-column justify-start align-center"
      min-height="98%"
      min-width="95%"
      elevation="0"
    >
      <header class="d-flex flex-column mt-5 mb-10">
        <p class="text-center font-weight-regular mb-15 mt-5">
          New Damage Scope
        </p>
        <p class="text-center font-weight-light text-h4">Structures</p>
        <p class="mb-2 text-center mb-10">Add and Edit Structures</p>
        <span class="text-center" style="font-size: 12px"
          >If you are using Company Cam or any other project documentation app,
          please add The link at the end this submission</span
        >
      </header>
      <div class="main-container">
        <v-btn
          elevation="0"
          small
          class="mr-3"
          fab
          dark
          color="yellow darken-2"
          style="height: 30px; width: 30px"
          @click="newStructure = !newStructure"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <p class="ma-0">New Structure</p>
      </div>
      <v-alert class="mt-3" v-if="error" type="error" dense text
        >An error has occurred, please try again
      </v-alert>
      <div v-if="newStructure" class="main-container mt-5">
        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
          <p>
            Enter structure type ( e.g. House, Barn, Garage, Building, etc . )
          </p>
          <v-text-field
            v-model="structureType"
            label="Enter Structure Type*"
            :rules="structureTypeRules"
            outlined
            dense
            clearable
          ></v-text-field>
          <v-btn block @click="createStructure" class="edit-button"
            >Save Structure</v-btn
          >
        </v-form>
      </div>
      <div
        v-for="(structure, index) in structures"
        :key="index"
        class="scope-container mt-5"
        @click="toggleDialog(index)"
      >
        <v-btn
          small
          fab
          dark
          elevation="0"
          class="delete-button"
          @click.stop="deleteStructure(index)"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
        <v-img
          max-height="90px"
          max-width="100px"
          src="@/assets/cards/scope/house.svg"
          alt=""
          class="mb-2"
        />
        <div class="pa-3 white--text">
          <p class="ma-0">Click To Complete Structure For</p>
          <p class="ma-0 font-weight-black">{{ structure.structure_name }}</p>
        </div>
        <ScopeDialog
          :dialog.sync="dialog[index]"
          :structure="structure"
          :index="index"
        />
      </div>
      <footer class="d-flex mt-15 px-10">
        <v-btn block @click="goBack" class="mt-10 back-button"
          >Back To Options</v-btn
        >
      </footer>
    </v-card>
  </v-container>
</template>

<script>
import ScopeDialog from "./ScopeDialog.vue";
import scope from "@/api/scope";
export default {
  name: "Scope",
  components: { ScopeDialog },
  data() {
    return {
      valid: false,
      newStructure: false,
      error: false,
      dialog: {},
      structureType: "",
      structureTypeRules: [
        (v) => !!v || "Structure Type is required",
        (v) =>
          (v && v.length >= 4) ||
          "Structure Type must be at least 5 characters",
      ],
      structures: [],
    };
  },
  methods: {
    goBack() {
      this.resetComponent();
      this.$router.go(-1);
    },
    resetComponent() {
      this.valid = false;
      this.newStructure = false;
      this.error = false;
      this.dialog = {};
      this.structureType = "";
      this.structures = [];
    },
    async createStructure() {
      if (this.$refs.form.validate()) {
        this.error = false;
        let structure = {
          project_id: this.$route.params.id,
          structure_name: this.structureType,
        };

        try {
          let response = await scope.createStructure(structure);
          if (response.code === 200) {
            this.structures.push(response.data.structure);
          } else {
            this.error = true;
          }
        } catch (error) {
          this.error = true;
        }

        this.newStructure = false;
        this.structureType = "";
      }
    },
    async deleteStructure(index) {
      try {
        let response = await scope.deleteStructure(
          this.structures[index].structure_id,
        );
        if (response.code === 200) {
          this.structures.splice(index, 1);
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      }
    },
    toggleDialog(index) {
      this.$set(this.dialog, index, !this.dialog[index]);
    },
    async createScopeProject() {
      try {
        let response = await scope.createProjectScope(this.$route.params.id);
        if (response.code === 400) {
          await this.getScope();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getScope() {
      try {
        let response = await scope.getProjectScope(this.$route.params.id);
        if (response.code === 200) {
          this.structures = response.data.scope.structures;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  activated() {
    this.createScopeProject();
  },
};
</script>

<style scoped>
header,
footer,
.main-container {
  width: 95%;
}

.main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #b5c0cc;
  border-radius: 12px;
  padding: 15px;
}

.scope-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 160px;
  width: 95%;
  border-radius: 12px;
  background-color: #2b58e2;
  border-radius: 12px;
  padding: 15px;
  cursor: pointer;
  position: relative;
}

@media (min-width: 768px) {
  header,
  footer,
  .main-container,
  .scope-container {
    width: 35%;
  }
}

.back-button {
  background-color: #e0e0e0 !important;
  color: black !important;
  text-transform: none;
  min-width: 175px !important;
  font-size: 15px !important;
  font-family: sans-serif !important;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent !important;
  color: white !important;
}
</style>
