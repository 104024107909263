<template>
  <v-container
    fluid
    class="d-flex flex-column justify-start align-center"
    style="height: 100%"
  >
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Header v-if="!loading" :details="details" />
    <div v-if="!loading" style="width: 98%">
      <v-tabs v-model="tab" center-active show-arrows>
        <v-tab>{{ $t("history.fullView.details") }}</v-tab>
        <v-tab>{{ $t("history.fullView.contacts") }}</v-tab>
        <v-tab>{{ $t("history.fullView.files") }}</v-tab>
        <v-tab>{{ $t("history.fullView.forms") }}</v-tab>
        <v-tab
          v-if="
            ['superuser', 'developer'].includes($store.getters.getUser.role)
          "
          >{{ $t("history.fullView.config") }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Details :details="details" />
        </v-tab-item>
        <!-- <v-tab-item>
          <Scope :scope="scope" />
        </v-tab-item> -->
        <v-tab-item>
          <Contacts
            :contacts="contacts"
            @contacts-updated="handleContactsUpdated"
          />
        </v-tab-item>
        <v-tab-item>
          <Files @file-uploaded="handleFileUploaded" :folders="files" />
        </v-tab-item>
        <v-tab-item>
          <Forms />
        </v-tab-item>
        <v-tab-item
          v-if="
            ['superuser', 'developer'].includes($store.getters.getUser.role)
          "
        >
          <Config :details="details" @updateProject="updateProject" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- ... -->
    <v-dialog
      style="z-index: 1003; max-width: 90vw"
      v-model="$store.state.modalDocuments.dialogPdfViewer"
    >
      <v-card>
        <!-- PDF Viewer -->
        <vue-pdf-app
          v-if="$store.getters.getModalDocuments.isPdf"
          style="height: 100vh; width: 90vw"
          :pdf="$store.getters.getModalDocuments.documentUrl"
          theme="dark"
        ></vue-pdf-app>
        <!-- Office Documents Viewer -->
        <VueDocPreview
          v-else-if="$store.getters.getModalDocuments.isOfficeDocument"
          style="height: 100vh; width: 90vw"
          :value="$store.getters.getModalDocuments.documentUrl"
          type="office"
        />
        <!-- Image Viewer -->
        <v-img
          v-else
          :src="getImage($store.getters.getModalDocuments.documentUrl)"
          style="height: 80vh; width: 70vw; max-width: 70vw"
          contain
        />
        <v-card-actions
          v-if="
            !$store.getters.getModalDocuments.isPdf &&
            !$store.getters.getModalDocuments.isOfficeDocument
          "
        >
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="downloadDocument">
            {{ $t("history.fullView.downloadImage") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Header from "@/components/history/fullview/Header.vue";
import Details from "@/components/history/fullview/Details.vue";
import Contacts from "@/components/history/fullview/Contacts.vue";
import Files from "@/components/history/fullview/Files.vue";
import Forms from "@/components/history/fullview/Forms.vue";
import Config from "@/components/history/fullview/Config.vue";
import fullProject from "@/api/fullProject";
import scope from "@/api/scope";
import team from "@/api/team";
import project from "@/api/project";
import VueDocPreview from "vue-doc-preview";
import heic2any from "heic2any";
export default {
  components: {
    Header,
    Contacts,
    Files,
    Forms,
    Config,
    Details,
    VueDocPreview,
  },
  name: "HistoryFullView",
  data() {
    return {
      loading: true,
      tab: null,
      details: {},
      scope: {},
      contacts: [],
      files: {},
      iosImage: null,
    };
  },
  async activated() {
    this.loading = true;
    await this.fetchDetail();
    // this.getScope();
    this.getContacts();
    this.getFiles();
    this.loading = false;
  },
  deactivated() {
    this.tab = null;
  },
  methods: {
    async fetchDetail() {
      try {
        const response = await fullProject.fetchDetails(this.$route.params.id);
        if (response.code == 200) {
          this.details = response.data;
        } else {
          this.$router.push({ name: "History" });
        }
      } catch (error) {
        this.$router.push({ name: "History" });
      }
    },
    async getScope() {
      try {
        const response = await scope.getProjectScope(this.$route.params.id);
        if (response.code == 200 && response.data && response.data.scope) {
          this.scope = response.data.scope;
        } else {
          this.$router.push({ name: "History" });
        }
      } catch (error) {
        this.$router.push({ name: "History" });
      }
    },
    async getContacts() {
      try {
        const response = await team.fetchContact(this.$route.params.id);
        if (response.code == 200 && response.data && response.data.contacts) {
          this.contacts = response.data.contacts;
        }
      } catch (error) {
        this.$router.push({ name: "History" });
      }
    },
    async getFiles() {
      try {
        const response = await project.fetchFiles(this.$route.params.id);
        if (response.code == 200 && response.data) {
          this.files = response.data;
        }
      } catch (error) {
        this.$router.push({ name: "History" });
      }
    },
    getImage(path) {
      const type = path.split(".")[path.split(".").length - 1];
      if (["jpg", "jpeg", "png", "gif"].includes(type)) {
        return path;
      } else if (["heic", "heif"].includes(type)) {
        if (this.iosImage) {
          return this.iosImage;
        } else {
          this.loadHeicImage(path);
          return require("@/assets/projectfullview/loading.svg");
        }
      } else {
        return require("@/assets/projectfullview/document.svg");
      }
    },
    async loadHeicImage(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();

        const convertedBlob = await heic2any({
          blob,
          toType: "image/jpeg",
        });
        const objectUrl = URL.createObjectURL(convertedBlob);

        this.iosImage = objectUrl;
      } catch (error) {
        console.error("Error al convertir la imagen HEIC:", error);
        this.iosImage = require("@/assets/projectfullview/document.svg");
      }
    },
    updateProject(project) {
      this.details.project = project;
    },
    handleContactsUpdated(updatedContacts) {
      this.contacts = updatedContacts;
    },
    handleFileUploaded() {
      this.getFiles();
    },
    downloadDocument() {
      window.open(this.$store.getters.getModalDocuments.documentUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: transparent !important;
}

img {
  max-width: 100%;
  height: auto;
}

::v-deep .v-dialog {
  width: auto;
}
</style>
