<template>
  <v-container fluid class="my-3 mx-0 pa-0">
    <v-row>
      <v-col cols="12" md="6">
        <div
          class="d-flex justify-space-between align-center"
          style="width: 100%"
        >
          <p class="mt-5 mb-8 mx-2" style="font-size: 20px">
            {{ $t("history.elements.details.insuredInfo") }}
          </p>
          <v-btn
            class="edit-button"
            @click="
              $router.push({
                name: 'CustomerInformation',
                params: { id: $route.params.id },
              })
            "
          >
            {{ $t("history.elements.details.editCustomer") }}
          </v-btn>
        </div>
        <v-card elevation="0">
          <v-row class="py-5 px-10">
            <v-col
              v-for="(insured, index) in insuredInfo"
              :key="index"
              class="elements"
              cols="12"
            >
              <span class="mr-3">{{ insured.text }}</span>
              <span>{{ insured.value }}</span>
            </v-col>
          </v-row>
        </v-card>
        <p class="mt-8 mb-8 mx-2" style="font-size: 20px">
          {{ $t("history.elements.details.claimInfo") }}
        </p>
        <v-card elevation="0">
          <v-row class="py-5 px-10">
            <v-col
              v-for="(claimInfo, index) in claimInformationDetails"
              :key="index"
              class="elements"
              cols="12"
            >
              <span class="mr-3">{{ claimInfo.text }}</span>
              <span class="clain--info__key">{{ claimInfo.value }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-tabs v-model="tab" center-active show-arrows>
          <v-tab>{{ $t("history.elements.details.update") }}</v-tab>
          <v-tab>{{ $t("history.elements.details.payments") }}</v-tab>
          <v-tab>
            <v-badge
              :content="details.project.unseen"
              :value="details.project.unseen"
              color="#2b58e2"
            >
              {{ $t("history.elements.details.timeline") }}
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Updates />
          </v-tab-item>
          <v-tab-item>
            <Payment />
          </v-tab-item>
          <v-tab-item>
            <Timeline />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import Updates from "./details/Updates.vue";
import Payment from "./details/Payment.vue";
import Timeline from "./details/TimeLine.vue";
export default {
  name: "Details",
  components: { Updates, Payment, Timeline },
  data() {
    return {
      tab: null,
    };
  },
  props: {
    details: Object,
  },
  computed: {
    insuredInfo() {
      if (this.details && this.details.customer_information) {
        const info = this.details.customer_information;
        return [
          {
            text: this.$t("history.elements.details.name"),
            value: info.name,
          },
          {
            text: this.$t("history.elements.details.email"),
            value: info.customer_email_address,
          },
          {
            text: this.$t("history.elements.details.phone"),
            value: info.customer_phone_number,
          },
          {
            text: this.$t("history.elements.details.insuredAddress"),
            value: `${info.insured_address} ${info.insured_city}, ${info.insured_state} ${info.insured_zip_code}`,
          },
        ];
      }
      return [];
    },
    claimInformationDetails() {
      if (this.details && this.details.customer_information) {
        const info = this.details.customer_information;

        return [
          {
            text: this.$t("history.elements.details.lossAddress"),
            value: `${info.address} ${info.city}, ${info.state} ${info.zip_code}`,
          },
          {
            text: this.$t("history.elements.details.causeOfLoss"),
            value: info.cause_of_loss,
          },
          {
            text: this.$t("history.elements.details.lossDescription"),
            value: info.claim_notes,
          },
          {
            text: this.$t("history.elements.details.companycamLinks"),
            value: info.companycam,
          },
          {
            text: this.$t("history.elements.details.claimNumber"),
            value: info.claim_number,
          },
          {
            text: this.$t("history.elements.details.claimStatus"),
            value: info.claim_status,
          },
          {
            text: this.$t("history.elements.details.dateOfLoss"),
            value: this.formatDate(info.date_of_loss_or_storm),
          },
          {
            text: this.$t("history.elements.details.inspectedByInsurance"),
            value: info.inspected_by_insurance_company
              ? this.$t("history.elements.details.yes")
              : this.$t("history.elements.details.no"),
          },
          {
            text: this.$t("history.elements.details.reportedToInsurance"),
            value: info.reported_to_insurance_company
              ? this.$t("history.elements.details.yes")
              : this.$t("history.elements.details.no"),
          },
          {
            text: this.$t("history.elements.details.insuranceCompany"),
            value: info.insurance_company,
          },
          {
            text: this.$t("history.elements.details.policyNumber"),
            value: info.policy_number,
          },
          {
            text: this.$t("history.elements.details.stateOfEmergency"),
            value: info.state_of_emergency
              ? this.$t("history.elements.details.yes")
              : this.$t("history.elements.details.no"),
          },
          {
            text: this.$t("history.elements.details.hasMortgage"),
            value: info.has_mortgage
              ? this.$t("history.elements.details.yes")
              : this.$t("history.elements.details.no"),
          },
          {
            text: this.$t("history.elements.details.mortgageCompany"),
            value: info.mortgage_company,
          },
        ];
      }

      return [];
    },
  },
  methods: {
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const formattedDate = date.format("MM / DD / YYYY");
      return formattedDate;
    },
  },
};
</script>

<style scoped>
.elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  margin-bottom: 12px;
  min-height: 40px;
}

.elements:nth-of-type(odd) {
  background-color: #fbfbfb;
  border: 1px solid #f4f4f4;
}

.clain--info__key {
  max-width: 60% !important;
  text-align: justify !important;
}

.v-tabs,
::v-deep .v-item-group,
.v-tabs-items {
  background-color: transparent !important;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 30px !important;
}
</style>
