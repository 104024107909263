import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// define the translations
const messages = {
  en: {
    login: {
      interest: {
        top: "Our interest is.",
        bottom: "your interest.",
      },
      email: "E-mail address",
      password: "Password",
      rememberMe: "Remember me",
      signIn: "Sign in",
      invalidEmailOrPassword: "Invalid password or email.",
      signInFailed: "Sign in failed. Please try again.",
      required: "This field is required.",
      invalidEmail: "Invalid email.",
      forgotPassword: "Forgot Password?",
      resetPassword: "Reset Password",
      resetFailed: "Password reset failed. Please try again.",
      backToLogin: "Back to Login",
      resetSuccess:
        "A new, provisional password has been assigned to your account. Please check your email.",
      register: "Register Here",
    },
    sidebar: {
      profile: "Profile",
      newSubmission: {
        top: "New",
        bottom: "Submission",
      },
      dashboard: "Dashboard",
      history: "History",
      team: "Team",
      companies: "Companies",
      activities: "Activities",
      dropdown: "Dropdown",
      logout: "Log Out",
    },
    profile: {
      userProfileCard: {
        editProfile: "Edit Profile",
      },
      userInformationCard: {
        personalInformation: "Personal Information",
        name: "Name",
        email: "E-mail Address",
        phone: "Phone Number",
        saveChanges: "Save changes",
        nameRequired: "Name is required",
        nameMinLength: "Name must be at least 5 characters",
        phoneRequired: "Phone number is required",
        profileUpdated: "Profile updated successfully.",
        updateFailed: "Update failed. Please try again.",
      },
      userPasswordCard: {
        currentPassword: "Current Password",
        newPassword: "New Password",
        updatePassword: "Update Password",
        saveChanges: "Save changes",
        passwordRequired: "Password is required",
        passwordMinLength: "Password must be at least 5 characters",
        passwordUpdated: "Password updated successfully.",
        passwordUpdateFailed: "Update failed. Incorrect password.",
        generalUpdateFailed: "Update failed. Please try again.",
      },
      jobInformationCard: {
        jobInformation: "Job Information",
        jobTitle: "Job Title",
        entryDate: "Entry Date",
        saveChanges: "Save Changes",
        jobUpdated: "Job information updated successfully.",
        updateFailed: "Update failed. Please try again.",
      },
    },
    projects: {
      newProjectBasicInformation: {
        newSubmission: "New Submission",
        letsGetStarted: "LET’S GET STARTED",
        enterUniqueName:
          "Enter a unique name. This can be an address, client name, or other info you can identify this scope by later.",
        insuredName: "Insured First Name and Last Name",
        address: "Address",
        addressLine2: "Address Line 2",
        city: "City",
        state: "State",
        zipCode: "Zip Code",
        company: "Company",
        confirmProjectLocation: "Confirm Project Location",
        errorOccurred: "An error has occurred, please try again",
        nameRequired: "Name is required",
        nameMinLength: "Name must be at least 5 characters",
        addressRequired: "Address is required",
        addressMinLength: "Address must be at least 5 characters",
        cityRequired: "City is required",
        cityMinLength: "City must be at least 4 characters",
        stateRequired: "State is required",
        stateMinLength: "State must be at least 2 characters",
        zipRequired: "Zip is required",
        zipMustBeNumber: "Zip Code must be a number",
        companyRequired: "Company is required",
      },
      newProjectChargeData: {
        newSubmission: "New Submission",
        createNewFile: "Create New Submission",
        customer: "Customer",
        information: "Information",
        customerDescription:
          "Please fill out your contact details and address accurately for efficient communication and service delivery.",
        uploadNow: "Upload Now",
        external: {
          top: "External",
          bottom: "Damages",
          description: "Upload all relevant files related to external damages.",
        },
        internal: {
          top: "Internal",
          bottom: "Damages",
          description: "Upload all relevant files related to internal damages.",
        },
        project: {
          top: "Project",
          bottom: "Documents",
          description:
            "Upload any necessary documents like permits, insurance claims, or previous repair records for a complete view of the project.",
        },
        submitClaim: "Submit Claim",
        goToDashboard: "Go to Dashboard",
        claimSubmitted: "Claim submitted successfully",
        completeLater:
          "It's essential to complete at least the customer information to enable the ‘Complete Later’ button. Your cooperation ensures smooth progress.",
      },
      cards: {
        customerInformation: {
          newSubmission: "New Submission",
          customerInformation: "Customer Information",
          customerName: "Customer Name *",
          lossAddress: "Loss address",
          address: "Address *",
          addressLine2: "Address Line 2",
          city: "City *",
          state: "State *",
          zipCode: "Zip Code *",
          insuredMailingAddress: "Insured Mailing Address",
          useLossAddress: "Use Loss Address as Mailing Address",
          contactInfo: "Contact Info",
          customerEmail: "Customer E-mail Address *",
          customerPhone: "Customer Cell Phone Number *",
          additionalInsuredName: "Additional Insured Name",
          insuranceCompany: "Insurance Company",
          policyNumber: "Policy Number",
          claimNumber: "Claim Number",
          causeOfLoss: "Cause of Loss",
          claimInspected:
            "Has This Claim Been Inspected By The Insurance Company?",
          claimReported:
            "Has This Claim Been Reported To The Insurance Company?",
          dateOfLoss: "Date of Loss Or Storm",
          currentClaimStatus: "Current Status of Claim",
          stateOfEmergency:
            "Did This Weather Event Cause The Governor To Declare A State Of Emergency?",
          companyCamLinks: "CompanyCam Links",
          descriptionOfLoss: "Description of Loss / Claim Notes",
          backToOptions: "Back To Options",
          confirmInformation: "Confirm Information",
          errorOccurred: "An error has occurred, please try again",
          customerNameRequired: "Customer name is required",
          customerNameMinLength: "Customer name must be at least 4 characters",
          addressRequired: "Address is required",
          addressMinLength: "Address must be at least 5 characters",
          cityRequired: "City is required",
          cityMinLength: "City must be at least 4 characters",
          stateRequired: "State is required",
          stateMinLength: "State must be at least 2 characters",
          zipRequired: "Zip is required",
          zipNumber: "Zip Code must be a number",
          emailRequired: "E-mail is required",
          emailValid: "E-mail must be valid",
          phoneRequired: "Phone number is required",
          phoneMinLength: "Phone number must be at least 10 characters",
          causeOfLossOptions: {
            hail: "Hail",
            wind: "Wind",
            hailWind: "Hail & Wind",
            hurricaneWind: "Hurricane Wind",
            hurricaneFlood: "Hurricane Flood",
            flood: "Flood",
            fire: "Fire",
            smoke: "Smoke",
            fireSmoke: "Fire & Smoke",
            fallenTree: "Fallen Tree",
            lightning: "Lightning",
            tornado: "Tornado",
            vandalism: "Vandalism",
            marine: "Marine",
            water: "Water",
            other: "Other",
          },
          claimStatusOptions: {
            new: "New",
            reopen: "Reopen",
            denied: "Denied",
          },
          stateOfEmergencyOptions: {
            stateOfEmergency:
              "State of Emergency (Hurricane 1 year ago or less)",
            nonEmergency: "Non-Emergency",
          },
          booleanOptions: {
            yes: "Yes",
            no: "No",
          },
          hasMortgage: "Does the homeowner have a mortgage?",
          mortgageName: "Mortgage Company Name",
          mortgageNameMinLength:
            "Mortgage Company Name must be at least 5 characters",
        },
        documents: {
          uploadDocuments: "UPLOAD DOCUMENTS",
          uploadInstructions: "Please upload your documents below",
          uploadLabel: "Upload Documents",
          selectFiles: "Select your files",
          more: "more",
          backToOptions: "Back To Options",
          confirmInformation: "Confirm Information",
          errorOccurred: "An error has occurred, please try again",
          noFilesSelected: "No files selected",
          invalidFormat: "Invalid file format",
          fileSizeExceeded: `File size should be less than ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
        },
        file: {
          uploadFiles: "UPLOAD FILES",
          uploadInstructions: "Please upload your files below",
          uploadLabel: "Upload files",
          selectFiles: "Select your files",
          more: "more",
          backToOptions: "Back To Options",
          confirmInformation: "Confirm Information",
          errorOccurred: "An error has occurred, please try again",
          noFilesSelected: "No files selected",
          invalidFormat: "Invalid file format",
          fileSizeExceeded: `File size should be less than ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
        },
        internalFile: {
          uploadFiles: "UPLOAD FILES",
          uploadInstructions: "Please upload your files below",
          uploadLabel: "Upload files",
          selectFiles: "Select your files",
          more: "more",
          backToOptions: "Back To Options",
          confirmInformation: "Confirm Information",
          errorOccurred: "An error has occurred, please try again",
          noFilesSelected: "No files selected",
          invalidFormat: "Invalid file format",
          fileSizeExceeded: `File size should be less than ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
        },
      },
    },
    dashboard: {
      header: {
        totalClaims: "Total Claims",
        intakePending: "Intake Pending",
        intakeRejected: "Intake Rejected",
        claimOpen: "Claim Open",
        claimClosed: "Claim Closed",
        totalWorkers: "Total Workers",
      },
      table: {
        recentHistory: "Recent History",
        fullHistory: "Full History",
        fullView: "Full View",
        headers: {
          number: "SUB#",
          name: "Name",
          LOR: "LOR",
          intake: "Intake",
          status: "Status",
          address: "Address",
          created_at: "Created Date",
          company_name: "Company",
        },
      },
      activities: {
        title: "Activities",
        newUpdate: "New Update",
        at: "At",
      },
    },
    companies: {
      search: "Search",
      newCompany: "New Company",
      name: "Name",
      email: "Email",
      address: "Address",
      phoneNumber: "Phone Number",
      types: "Types",
      sellers: "Sellers",
      notes: "Notes",
      errorOccurred: "An error has occurred, please try again",
      close: "Close",
      save: "Save",
      createAdmin: "Create Admin",
      editCompany: "Edit Company",
      companyHistory: "Company History",
      headers: {
        name: "Name",
        email: "Email",
        address: "Address",
        phone_number: "Phone Number",
        created_at: "Created Date",
        company_type: "Company Type",
        sellers: "Sellers",
      },
      validation: {
        name: {
          required: "Name is required",
          minLength: "Name must be at least 3 characters",
        },
        email: {
          required: "Email is required",
          valid: "Email must be valid",
        },
        address: {
          required: "Address is required",
          minLength: "Address must be at least 5 characters",
        },
        phone: {
          required: "Phone number is required",
          minLength: "Phone number must be at least 5 characters",
        },
      },
      typesOptions: {
        roofing: "Roofing",
        mitigation: "Mitigation",
        remodeling: "Remodeling",
        other: "Other",
      },
      sellersOptions: {
        "1-5": "1-5",
        "6-15": "6-15",
        "15+": "15+",
      },
    },
    team: {
      teamInfo: {
        search: "Search",
        newMember: "New Member",
        headers: {
          entry_date: "Entry Date",
          job_title: "Job Title",
          claims: "Claims",
          name: "Name",
          email: "Email",
          phone: "Phone",
        },
      },
      createMember: {
        newMember: "New member",
        name: "Name",
        email: "Email",
        phoneNumber: "Phone Number",
        password: "Password",
        confirmationPassword: "Confirmation Password",
        entryDate: "Entry Date",
        jobTitle: "Job Title",
        confirmAccount: "Confirm Account",
        accountCreated: "The account has been created.",
        createAnotherAccount: "Create another account",
        viewCompanies: "View Companies",
        viewTeam: "View team",
        accountCreationFailed: "Account creation failed.",
        tryAgain: "Try again",
        validation: {
          name: {
            required: "Name is required",
            minLength: "Name must be at least 5 characters",
          },
          email: {
            required: "E-mail is required",
            valid: "E-mail must be valid",
          },
          phone: {
            required: "Phone is required",
            minLength: "Phone must be at least 5 characters",
          },
          password: {
            required: "Password is required",
            minLength: "Password must be at least 5 characters",
          },
          confirmationPassword: {
            required: "Confirmation Password is required",
            match: "Passwords must match",
          },
          date: {
            required: "Date is required",
          },
          jobTitle: {
            required: "Job Title is required",
            minLength: "Job Title must be at least 5 characters",
          },
        },
      },
    },
    history: {
      principalHistory: {
        recentHistory: "Recent History",
        fullHistory: "Full History",
        headers: {
          number: "SUB#",
          name: "Name",
          LOR: "LOR",
          intake: "Intake",
          status: "Status",
          address: "Address",
          created_at: "Created Date",
          company_name: "Company",
        },
        fullView: "Full View",
        editName: "Edit Name",
        close: "Close",
        save: "Save",
        validation: {
          name: {
            required: "Name is required",
            minLength: "Name must be at least 5 characters",
          },
        },
        errorOccurred: "An error has occurred, please try again",
        projectUpdated: "Project name has been updated successfully",
        search: "Search",
        all: "All",
        pending: "Pending",
        rejected: "Rejected",
        incomplete: "Incomplete",
        status: "Open",
      },
      fullView: {
        details: "Details",
        contacts: "Contacts",
        files: "Files",
        forms: "Forms",
        config: "Status",
        downloadImage: "Download Image",
        editProjectName: "Edit Project Name",
        name: "Name",
        save: "Save",
        close: "Close",
        errorOccurred: "An error has occurred, please try again",
        projectUpdated: "Project name has been updated successfully",
      },
      elements: {
        header: {
          client: "Client",
          subNumber: "Company Name",
          claimStatus: "Claim Status",
          totalPayments: "Total Payments",
          lastPayment: "Last payment",
        },
        details: {
          insuredInfo: "Insured Info",
          editCustomer: "Edit Customer",
          claimInfo: "Claim Info",
          update: "Updates",
          payments: "Payment",
          timeline: "Timeline",
          name: "Name",
          email: "Email",
          phone: "Phone",
          insuredAddress: "Insured Address",
          lossAddress: "Loss Address",
          causeOfLoss: "Cause of Loss",
          lossDescription: "Loss Description / Claim Notes",
          companycamLinks: "Companycam Links",
          claimNumber: "Claim Number",
          claimStatus: "Claim Status",
          dateOfLoss: "Date of Loss or Storm",
          inspectedByInsurance: "Inspected by Insurance Company",
          reportedToInsurance: "Reported to Insurance Company",
          insuranceCompany: "Insurance Company",
          policyNumber: "Policy Number",
          stateOfEmergency: "State of Emergency",
          hasMortgage: "Has Mortgage",
          mortgageCompany: "Mortgage Company Name",
          yes: "Yes",
          no: "No",
          payment: {
            total: "Total",
            amount: "Payment Amount",
            description: "Payment Description",
            detail: "Payment Detail",
            upload: "Upload files",
            newPayment: "New Payment",
            attachments: "Attachments",
            reply: "Reply",
            replyDetail: "Reply Detail",
            sendReply: "Send Reply",
            error: "An error has occurred, please try again",
            replyError:
              "There was an error submitting your reply. Please try again.",
            validation: {
              amountRequired: "Payment Amount is required",
              descriptionRequired: "Payment Description is required",
              detailRequired: "Payment Detail is required",
              maxFiles: `You can only upload up to ${process.env.VUE_APP_NUMBER_OF_FILES} files`,
              maxFileSize: `Each file must be less than ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
              replyRequired: "Reply Detail is required",
            },
          },
          updates: {
            threadDetail: "Thread Detail",
            upload: "Upload files",
            createThread: "Create Thread",
            attachments: "Attachments",
            reply: "Reply",
            replyDetail: "Reply Detail",
            sendReply: "Send Reply",
            error: "An error has occurred, please try again",
            replyError:
              "There was an error submitting your reply. Please try again.",
            validation: {
              threadRequired: "Thread Detail is required",
              maxFiles: `You can only upload up to ${process.env.VUE_APP_NUMBER_OF_FILES} files`,
              maxFileSize: `Each file must be less than ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
              replyRequired: "Reply Detail is required",
            },
          },
          timelines: {
            ClaimUpdated: "Claim Updated",
            ClaimCreated: "Claim Created",
            FileUploaded: "File Uploaded",
            ContactsUpdated: "Contacts Updated",
            Comment: "Comment",
            Payment: "Payment",
          },
        },
        contacts: {
          internalContacts: "Internal Contacts",
          addMoreContacts: "Add More Contacts",
          manageContacts: "Manage Contacts",
          cancel: "Cancel",
          save: "Save",
          headers: {
            name: "Name",
            job_title: "Job",
            email: "E-mail",
            phone: "Phone",
          },
        },
        files: {
          files: "Files",
          addNew: "Add New",
          folder: "Folder",
          addNewFolder: "Add New Folder",
          folderName: "Folder Name",
          cancel: "Cancel",
          save: "Save",
          upload: "Upload",
          close: "Close",
          uploadDocuments: "Upload Documents",
          selectDocuments: "Select documents",
          uploadLimit: `You can only upload up to ${process.env.VUE_APP_NUMBER_OF_FILES} files`,
          fileSizeLimit: `Each file must be less than ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
          folderCreated: "Folder created successfully",
          documentUploaded: "Document uploaded successfully",
          documentDeleted: "Document deleted successfully",
          errorOccurred: "An error has occurred, please try again",
          delete: "Delete File",
          edit: "Edit Name",
          editDocument: "Edit File Name",
          documentEdited: "Document name edited successfully",
          documentName: "File Name",
          requiredName: "Name is required",
        },
        forms: {
          lor: "LOR",
          noLOR: "No Letter of Representation record found for this Project.",
          resend: "Resend",
          error: "An error has occurred, please try again",
          success: "LOR sent successfully",
          email_send_date: "Email Send Date",
          email_status: "Email Status",
          reminder_sent_date: "Reminder Sent Date",
          status: "Status",
          Sent: "Sent",
          Unsigned: "Unsigned",
          Signed: "Signed",
        },
        config: {
          editStatusAndIntake: "Edit Status and Intake",
          status: "Status",
          intake: "Intake",
          update: "Update",
          error: "An error has occurred, please try again",
          success: "Status and Intake updated successfully",
          statusOptions: {
            open: "Open",
            closed: "Closed",
            tbd: "TBD",
            inspection: "Inspection",
            negotiations: "Negotiations",
            appraisal: "Appraisal",
            settled: "Settled",
            depreciation: "Depreciation",
          },
          intakeOptions: {
            approved: "Approved",
            pending: "Pending",
            rejected: "Rejected",
            incomplete: "Incomplete",
          },
        },
      },
    },
    activities: {
      search: "Search",
      headers: {
        creator: "Creator",
        date: "Date",
        event: "Event",
        project: "Claim",
      },
      fullView: "Full View",
    },
  },
  es: {
    login: {
      interest: {
        top: "Nuestro interés es",
        bottom: "tu interés.",
      },
      email: "Dirección de correo electrónico",
      password: "Contraseña",
      rememberMe: "Recuérdame",
      signIn: "Iniciar sesión",
      invalidEmailOrPassword: "Correo electrónico o contraseña inválidos.",
      signInFailed: "Error al iniciar sesión. Por favor, inténtelo de nuevo.",
      required: "Este campo es obligatorio.",
      invalidEmail: "Correo electrónico no válido.",
      forgotPassword: "¿Olvidaste tu contraseña?",
      resetPassword: "Restablecer contraseña",
      resetFailed:
        "Error al restablecer la contraseña. Por favor, inténtelo de nuevo.",
      backToLogin: "Volver al inicio de sesión",
      resetSuccess:
        "Se ha asignado una nueva contraseña provisional a su cuenta. Por favor, revise su correo electrónico.",
      register: "Regístrate aquí",
    },
    sidebar: {
      profile: "Perfil",
      newSubmission: {
        top: "Nuevo",
        bottom: "Proyecto",
      },
      dashboard: "Tablero",
      history: "Historial",
      team: "Equipo",
      companies: "Empresas",
      activities: "Actividades",
      dropdown: "Desplegar",
      logout: "Cerrar Sesión",
    },
    profile: {
      userProfileCard: {
        editProfile: "Editar Perfil",
      },
      userInformationCard: {
        personalInformation: "Información Personal",
        name: "Nombre",
        email: "Dirección de correo electrónico",
        phone: "Número de teléfono",
        saveChanges: "Guardar cambios",
        nameRequired: "El nombre es obligatorio",
        nameMinLength: "El nombre debe tener al menos 5 caracteres",
        phoneRequired: "El número de teléfono es obligatorio",
        profileUpdated: "Perfil actualizado con éxito.",
        updateFailed: "Error al actualizar. Por favor, inténtelo de nuevo.",
      },
      userPasswordCard: {
        currentPassword: "Contraseña Actual",
        newPassword: "Nueva Contraseña",
        updatePassword: "Actualizar Contraseña",
        saveChanges: "Guardar cambios",
        passwordRequired: "La contraseña es obligatoria",
        passwordMinLength: "La contraseña debe tener al menos 5 caracteres",
        passwordUpdated: "Contraseña actualizada con éxito.",
        passwordUpdateFailed: "Error al actualizar. Contraseña incorrecta.",
        generalUpdateFailed:
          "Error al actualizar. Por favor, inténtelo de nuevo.",
      },
      jobInformationCard: {
        jobInformation: "Información del Trabajo",
        jobTitle: "Título del Trabajo",
        entryDate: "Fecha de Ingreso",
        saveChanges: "Guardar Cambios",
        jobUpdated: "Información del trabajo actualizada con éxito.",
        updateFailed: "Error al actualizar. Por favor, inténtelo de nuevo.",
      },
    },
    projects: {
      newProjectBasicInformation: {
        newSubmission: "Nuevo proyecto",
        letsGetStarted: "EMPECEMOS",
        enterUniqueName:
          "Ingrese un nombre único. Puede ser una dirección, nombre del cliente u otra información con la que pueda identificar este alcance más tarde.",
        insuredName: "Nombre y Apellido del Asegurado",
        address: "Dirección",
        addressLine2: "Línea de Dirección 2",
        city: "Ciudad",
        state: "Estado",
        zipCode: "Código Postal",
        company: "Empresa",
        confirmProjectLocation: "Confirmar Ubicación del Proyecto",
        errorOccurred: "Ha ocurrido un error, por favor intente de nuevo",
        nameRequired: "El nombre es obligatorio",
        nameMinLength: "El nombre debe tener al menos 5 caracteres",
        addressRequired: "La dirección es obligatoria",
        addressMinLength: "La dirección debe tener al menos 5 caracteres",
        cityRequired: "La ciudad es obligatoria",
        cityMinLength: "La ciudad debe tener al menos 4 caracteres",
        stateRequired: "El estado es obligatorio",
        stateMinLength: "El estado debe tener al menos 2 caracteres",
        zipRequired: "El código postal es obligatorio",
        zipMustBeNumber: "El código postal debe ser un número",
        companyRequired: "La empresa es obligatoria",
      },
      newProjectChargeData: {
        newSubmission: "Nuevo Proyecto",
        createNewFile: "Crear Nuevo proyecto",
        customer: "Información del",
        information: "Cliente",
        customerDescription:
          "Por favor complete sus datos de contacto y dirección con precisión para una comunicación y entrega de servicios eficiente.",
        uploadNow: "Subir Ahora",
        external: {
          top: "Daños",
          bottom: "Externos",
          description:
            "Suba todos los archivos relevantes relacionados con daños externos.",
        },
        internal: {
          top: "Daños",
          bottom: "Internos",
          description:
            "Suba todos los archivos relevantes relacionados con daños internos.",
        },
        project: {
          top: "Documentos del",
          bottom: "Proyecto",
          description:
            "Suba cualquier documento necesario como permisos, reclamaciones de seguro o registros de reparaciones anteriores para una visión completa del proyecto.",
        },
        submitClaim: "Cargar Reclamo",
        goToDashboard: "Ir al Tablero",
        claimSubmitted: "Reclamo cargado con éxito",
        completeLater:
          "Es esencial completar al menos la información del cliente para habilitar el botón ‘Completar Más Tarde’. Su cooperación asegura un progreso sin problemas.",
      },
      cards: {
        customerInformation: {
          newSubmission: "Nuevo Proyecto",
          customerInformation: "Información del Cliente",
          customerName: "Nombre del Cliente *",
          lossAddress: "Dirección de Pérdida",
          address: "Dirección *",
          addressLine2: "Línea de Dirección 2",
          city: "Ciudad *",
          state: "Estado *",
          zipCode: "Código Postal *",
          insuredMailingAddress: "Dirección Postal del Asegurado",
          useLossAddress: "Usar Dirección de Pérdida como Dirección Postal",
          contactInfo: "Información de Contacto",
          customerEmail: "Correo Electrónico del Cliente *",
          customerPhone: "Número de Teléfono Celular del Cliente *",
          additionalInsuredName: "Nombre Adicional del Asegurado",
          insuranceCompany: "Compañía de Seguros",
          policyNumber: "Número de Póliza",
          claimNumber: "Número de Reclamo",
          causeOfLoss: "Causa de la Pérdida",
          claimInspected:
            "¿Este Reclamo Ha Sido Inspeccionado Por La Compañía de Seguros?",
          claimReported:
            "¿Este Reclamo Ha Sido Reportado A La Compañía de Seguros?",
          dateOfLoss: "Fecha de la Pérdida o Tormenta",
          currentClaimStatus: "Estado Actual del Reclamo",
          stateOfEmergency:
            "¿Este Evento Climático Causó Que El Gobernador Declarara Un Estado de Emergencia?",
          companyCamLinks: "Enlaces de CompanyCam",
          descriptionOfLoss: "Descripción de la Pérdida / Notas del Reclamo",
          backToOptions: "Volver a Opciones",
          confirmInformation: "Confirmar Información",
          errorOccurred: "Ha ocurrido un error, por favor intente de nuevo",
          customerNameRequired: "El nombre del cliente es obligatorio",
          customerNameMinLength:
            "El nombre del cliente debe tener al menos 4 caracteres",
          addressRequired: "La dirección es obligatoria",
          addressMinLength: "La dirección debe tener al menos 5 caracteres",
          cityRequired: "La ciudad es obligatoria",
          cityMinLength: "La ciudad debe tener al menos 4 caracteres",
          stateRequired: "El estado es obligatorio",
          stateMinLength: "El estado debe tener al menos 2 caracteres",
          zipRequired: "El código postal es obligatorio",
          zipNumber: "El código postal debe ser un número",
          emailRequired: "El correo electrónico es obligatorio",
          emailValid: "El correo electrónico debe ser válido",
          phoneRequired: "El número de teléfono es obligatorio",
          phoneMinLength:
            "El número de teléfono debe tener al menos 10 caracteres",
          causeOfLossOptions: {
            hail: "Granizo",
            wind: "Viento",
            hailWind: "Granizo y Viento",
            hurricaneWind: "Viento de Huracán",
            hurricaneFlood: "Inundación de Huracán",
            flood: "Inundación",
            fire: "Fuego",
            smoke: "Humo",
            fireSmoke: "Fuego y Humo",
            fallenTree: "Árbol Caído",
            lightning: "Relámpago",
            tornado: "Tornado",
            vandalism: "Vandalismo",
            marine: "Marino",
            water: "Agua",
            other: "Otro",
          },
          claimStatusOptions: {
            new: "Nuevo",
            reopen: "Reabrir",
            denied: "Denegado",
          },
          stateOfEmergencyOptions: {
            stateOfEmergency:
              "Estado de Emergencia (Huracán hace 1 año o menos)",
            nonEmergency: "No Emergencia",
          },
          booleanOptions: {
            yes: "Sí",
            no: "No",
          },
          hasMortgage: "¿El propietario tiene una hipoteca?",
          mortgageName: "Nombre de la compañía hipotecaria",
          mortgageNameMinLength:
            "El nombre de la compañía hipotecaria debe tener al menos 5 caracteres",
        },
        documents: {
          uploadDocuments: "CARGAR DOCUMENTOS",
          uploadInstructions: "Por favor, cargue sus documentos a continuación",
          uploadLabel: "Cargar Documentos",
          selectFiles: "Seleccione sus archivos",
          more: "más",
          backToOptions: "Volver a Opciones",
          confirmInformation: "Confirmar Información",
          errorOccurred: "Ha ocurrido un error, por favor intente nuevamente",
          noFilesSelected: "No se seleccionaron archivos",
          invalidFormat: "Formato de archivo inválido",
          fileSizeExceeded: `El tamaño del archivo debe ser menor a ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
        },
        file: {
          uploadFiles: "CARGAR ARCHIVOS",
          uploadInstructions: "Por favor, cargue sus archivos a continuación",
          uploadLabel: "Cargar archivos",
          selectFiles: "Seleccione sus archivos",
          more: "más",
          backToOptions: "Volver a Opciones",
          confirmInformation: "Confirmar Información",
          errorOccurred: "Ha ocurrido un error, por favor intente nuevamente",
          noFilesSelected: "No se seleccionaron archivos",
          invalidFormat: "Formato de archivo inválido",
          fileSizeExceeded: `El tamaño del archivo debe ser menor a ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
        },
        internalFile: {
          uploadFiles: "CARGAR ARCHIVOS",
          uploadInstructions: "Por favor, cargue sus archivos a continuación",
          uploadLabel: "Cargar archivos",
          selectFiles: "Seleccione sus archivos",
          more: "más",
          backToOptions: "Volver a Opciones",
          confirmInformation: "Confirmar Información",
          errorOccurred: "Ha ocurrido un error, por favor intente nuevamente",
          noFilesSelected: "No se seleccionaron archivos",
          invalidFormat: "Formato de archivo inválido",
          fileSizeExceeded: `El tamaño del archivo debe ser menor a ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
        },
      },
    },
    dashboard: {
      header: {
        totalClaims: "Reclamos Totales",
        intakePending: "Pendientes de Ingreso",
        intakeRejected: "Ingresos Rechazados",
        claimOpen: "Reclamos Abiertos",
        claimClosed: "Reclamos Cerrados",
        totalWorkers: "Trabajadores Totales",
      },
      table: {
        recentHistory: "Historial Reciente",
        fullHistory: "Historial Completo",
        fullView: "Vista Completa",
        headers: {
          number: "SUB#",
          name: "Nombre",
          LOR: "LOR",
          intake: "Ingreso",
          status: "Estado",
          address: "Dirección",
          created_at: "Fecha de Creación",
          company_name: "Compañía",
        },
      },
      activities: {
        title: "Actividades",
        newUpdate: "Nueva Actualización",
        at: "En",
      },
    },
    companies: {
      search: "Buscar",
      newCompany: "Nueva Empresa",
      name: "Nombre",
      email: "Correo Electrónico",
      address: "Dirección",
      phoneNumber: "Número de Teléfono",
      types: "Tipos",
      sellers: "Vendedores",
      notes: "Notas",
      errorOccurred: "Ocurrió un error, por favor intente nuevamente",
      close: "Cerrar",
      save: "Guardar",
      createAdmin: "Crear Admin",
      editCompany: "Editar Empresa",
      companyHistory: "Historial de la Empresa",
      headers: {
        name: "Nombre",
        email: "Correo Electrónico",
        address: "Dirección",
        phone_number: "Número de Teléfono",
        created_at: "Fecha de Creación",
        company_type: "Tipo de Empresa",
        sellers: "Vendedores",
      },
      validation: {
        name: {
          required: "El nombre es obligatorio",
          minLength: "El nombre debe tener al menos 3 caracteres",
        },
        email: {
          required: "El correo electrónico es obligatorio",
          valid: "El correo electrónico debe ser válido",
        },
        address: {
          required: "La dirección es obligatoria",
          minLength: "La dirección debe tener al menos 5 caracteres",
        },
        phone: {
          required: "El número de teléfono es obligatorio",
          minLength: "El número de teléfono debe tener al menos 5 caracteres",
        },
      },
      typesOptions: {
        roofing: "Techo",
        mitigation: "Mitigación",
        remodeling: "Remodelación",
        other: "Otro",
      },
      sellersOptions: {
        "1-5": "1-5",
        "6-15": "6-15",
        "15+": "15+",
      },
    },
    team: {
      teamInfo: {
        search: "Buscar",
        newMember: "Nuevo miembro",
        headers: {
          entry_date: "Fecha de Entrada",
          job_title: "Título del Trabajo",
          claims: "Reclamos",
          name: "Nombre",
          email: "Correo Electrónico",
          phone: "Teléfono",
        },
      },
      createMember: {
        newMember: "Nuevo miembro",
        name: "Nombre",
        email: "Correo Electrónico",
        phoneNumber: "Teléfono",
        password: "Contraseña",
        confirmationPassword: "Confirmar Contraseña",
        entryDate: "Fecha de Entrada",
        jobTitle: "Título del Trabajo",
        confirmAccount: "Confirmar Cuenta",
        accountCreated: "La cuenta ha sido creada.",
        createAnotherAccount: "Crear otra cuenta",
        viewCompanies: "Ver Compañías",
        viewTeam: "Ver equipo",
        accountCreationFailed: "Falló la creación de la cuenta.",
        tryAgain: "Inténtalo de nuevo",
        validation: {
          name: {
            required: "El nombre es obligatorio",
            minLength: "El nombre debe tener al menos 5 caracteres",
          },
          email: {
            required: "El correo electrónico es obligatorio",
            valid: "El correo electrónico debe ser válido",
          },
          phone: {
            required: "El teléfono es obligatorio",
            minLength: "El teléfono debe tener al menos 5 caracteres",
          },
          password: {
            required: "La contraseña es obligatoria",
            minLength: "La contraseña debe tener al menos 5 caracteres",
          },
          confirmationPassword: {
            required: "La confirmación de la contraseña es obligatoria",
            match: "Las contraseñas deben coincidir",
          },
          date: {
            required: "La fecha es obligatoria",
          },
          jobTitle: {
            required: "El título del trabajo es obligatorio",
            minLength: "El título del trabajo debe tener al menos 5 caracteres",
          },
        },
      },
    },
    history: {
      principalHistory: {
        recentHistory: "Historial Reciente",
        fullHistory: "Historial Completo",
        headers: {
          number: "SUB#",
          name: "Nombre",
          LOR: "LOR",
          intake: "Ingreso",
          status: "Estado",
          address: "Dirección",
          created_at: "Fecha de Creación",
          company_name: "Empresa",
        },
        fullView: "Vista Completa",
        editName: "Editar Nombre",
        close: "Cerrar",
        save: "Guardar",
        validation: {
          name: {
            required: "El nombre es obligatorio",
            minLength: "El nombre debe tener al menos 5 caracteres",
          },
        },
        errorOccurred: "Ocurrió un error, por favor intente de nuevo",
        projectUpdated:
          "El nombre del proyecto se ha actualizado correctamente",
        search: "Buscar",
        all: "Todos",
        pending: "Pendiente",
        rejected: "Rechazado",
        incomplete: "Incompleto",
        status: "Abierto",
      },
      fullView: {
        details: "Detalles",
        contacts: "Contactos",
        files: "Archivos",
        forms: "Formularios",
        config: "Estatus",
        downloadImage: "Descargar Imagen",
        editProjectName: "Editar Nombre del Proyecto",
        name: "Nombre",
        save: "Guardar",
        close: "Cerrar",
        errorOccurred: "Ocurrió un error, por favor intente de nuevo",
        projectUpdated:
          "El nombre del proyecto se ha actualizado correctamente",
      },
      elements: {
        header: {
          client: "Cliente",
          subNumber: "Nombre de la Empresa",
          claimStatus: "Estado del Reclamo",
          totalPayments: "Pagos Totales",
          lastPayment: "Último pago",
        },
        details: {
          insuredInfo: "Información del Asegurado",
          editCustomer: "Editar Cliente",
          claimInfo: "Información del Reclamo",
          update: "Actualizaciones",
          payments: "Pagos",
          timeline: "Cronología",
          name: "Nombre",
          email: "Correo Electrónico",
          phone: "Teléfono",
          insuredAddress: "Dirección del Asegurado",
          lossAddress: "Dirección de Pérdida",
          causeOfLoss: "Causa de la Pérdida",
          lossDescription: "Descripción de la Pérdida / Notas del Reclamo",
          companycamLinks: "Enlaces de Companycam",
          claimNumber: "Número de Reclamo",
          claimStatus: "Estado del Reclamo",
          dateOfLoss: "Fecha de Pérdida o Tormenta",
          inspectedByInsurance: "Inspeccionado por la Compañía de Seguros",
          reportedToInsurance: "Reportado a la Compañía de Seguros",
          insuranceCompany: "Compañía de Seguros",
          policyNumber: "Número de Póliza",
          stateOfEmergency: "Estado de Emergencia",
          hasMortgage: "Tiene Hipoteca",
          mortgageCompany: "Nombre de la Compañía Hipotecaria",
          yes: "Sí",
          no: "No",
          payment: {
            total: "Total",
            amount: "Monto del Pago",
            description: "Descripción del Pago",
            detail: "Detalle del Pago",
            upload: "Subir archivos",
            newPayment: "Nuevo Pago",
            attachments: "Adjuntos",
            reply: "Responder",
            replyDetail: "Detalle de Respuesta",
            sendReply: "Enviar Respuesta",
            error: "Ha ocurrido un error, por favor intenta nuevamente",
            replyError:
              "Hubo un error al enviar tu respuesta. Por favor intenta nuevamente.",
            success: "Estado e Ingreso actualizados con éxito",
            validation: {
              amountRequired: "El monto del pago es obligatorio",
              descriptionRequired: "La descripción del pago es obligatoria",
              detailRequired: "El detalle del pago es obligatorio",
              maxFiles: `Solo puedes subir hasta ${process.env.VUE_APP_NUMBER_OF_FILES} archivos`,
              maxFileSize: `Cada archivo debe ser menor de ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
              replyRequired: "El detalle de la respuesta es obligatorio",
            },
          },
          updates: {
            threadDetail: "Detalle del hilo",
            upload: "Subir archivos",
            createThread: "Crear hilo",
            attachments: "Adjuntos",
            reply: "Responder",
            replyDetail: "Detalle de la respuesta",
            sendReply: "Enviar respuesta",
            error: "Ha ocurrido un error, por favor intenta nuevamente",
            replyError:
              "Hubo un error al enviar tu respuesta. Por favor intenta nuevamente.",
            validation: {
              threadRequired: "El detalle del hilo es obligatorio",
              maxFiles: `Solo puedes subir hasta ${process.env.VUE_APP_NUMBER_OF_FILES} archivos`,
              maxFileSize: `Cada archivo debe ser menor de ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
              replyRequired: "El detalle de la respuesta es obligatorio",
            },
          },
          timelines: {
            ClaimUpdated: "Reclamo Actualizado",
            ClaimCreated: "Reclamo Creado",
            FileUploaded: "Archivo Subido",
            ContactsUpdated: "Contactos actualizados",
            Comment: "Comentario",
            Payment: "Pago",
          },
        },
        contacts: {
          internalContacts: "Contactos Internos",
          addMoreContacts: "Agregar Más Contactos",
          manageContacts: "Administrar Contactos",
          cancel: "Cancelar",
          save: "Guardar",
          headers: {
            name: "Nombre",
            job_title: "Puesto",
            email: "Correo Electrónico",
            phone: "Teléfono",
          },
        },
        files: {
          files: "Archivos",
          addNew: "Agregar Nuevo",
          folder: "Carpeta",
          addNewFolder: "Agregar Nueva Carpeta",
          folderName: "Nombre de la Carpeta",
          cancel: "Cancelar",
          save: "Guardar",
          upload: "Subir",
          close: "Cerrar",
          uploadDocuments: "Subir Documentos",
          selectDocuments: "Seleccionar documentos",
          uploadLimit: `Solo puedes subir hasta ${process.env.VUE_APP_NUMBER_OF_FILES} archivos`,
          fileSizeLimit: `Cada archivo debe ser menor de ${process.env.VUE_APP_WEIGHT_OF_FILES}MB`,
          folderCreated: "Carpeta creada con éxito",
          documentUploaded: "Documento subido con éxito",
          documentDeleted: "Documento eliminado con éxito",
          errorOccurred: "Ha ocurrido un error, por favor intenta nuevamente",
          delete: "Eliminar Archivo",
          edit: "Editar Nombre",
          editDocument: "Editar Nombre del Archivo",
          documentEdited: "Nombre del archivo editado con éxito",
          documentName: "Nombre del Archivo",
          requiredName: "El nombre es obligatorio",
        },
        forms: {
          lor: "LOR",
          noLOR:
            "No se encontró registro de la Carta de Representación para este Proyecto.",
          resend: "Reenviar",
          error: "Ha ocurrido un error, por favor intenta nuevamente",
          success: "LOR enviada con éxito",
          email_send_date: "Fecha de envío del correo electrónico",
          email_status: "Estado del correo electrónico",
          reminder_sent_date: "Fecha de envío del recordatorio",
          status: "Estado",
          Sent: "Enviado",
          Unsigned: "No firmado",
          Signed: "Firmado",
        },
        config: {
          editStatusAndIntake: "Editar Estado e Ingreso",
          status: "Estado",
          intake: "Ingreso",
          update: "Actualizar",
          error: "Ha ocurrido un error, por favor intenta nuevamente",
          success: "Estado e Ingreso actualizados con éxito",
          statusOptions: {
            open: "Abierto",
            closed: "Cerrado",
            tbd: "TBD",
            inspection: "Inspección",
            negotiations: "Negociaciones",
            appraisal: "Valoración",
            settled: "Acuerdo",
            depreciation: "Depreciación",
          },
          intakeOptions: {
            approved: "Aprobado",
            pending: "Pendiente",
            rejected: "Rechazado",
            incomplete: "Incompleto",
          },
        },
      },
    },
    activities: {
      search: "Buscar",
      headers: {
        creator: "Creador",
        date: "Fecha",
        event: "Evento",
        project: "Reclamo",
      },
      fullView: "Vista Completa",
    },
  },
};

// Configuration of vue-i18n
const i18n = new VueI18n({
  locale: "en", // idioma por defecto
  messages,
});

export default i18n;
