<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    style="height: 100%"
  >
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-width="95%"
      elevation="0"
    >
      <v-card-title style="width: 100%" class="px-10 py-7">
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              class="d-flex justify-center align-center"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('activities.search')"
              filled
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        style="width: 100%"
        height="75vh"
        :loading="loading"
        :headers="translatedHeaders"
        :items="filteredItems"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        mobile-breakpoint="768"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.description`]="{ item }">
          <span>{{ item.description }}</span>
        </template>
        <template v-slot:[`item.project_id`]="{ item }">
          <v-btn
            class="edit-button"
            @click="
              $router.push({
                name: 'HistoryFullView',
                params: { id: item.project_id },
              })
            "
          >
            {{ $t("activities.fullView") }}
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center py-5">
        <v-pagination
          total-visible="5"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import activities from "@/api/activities";
import moment from "moment";
export default {
  name: "Activities",
  data() {
    return {
      loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      headers: [
        { text: "Creator", value: "creator", width: "25%" },
        { text: "Date", value: "date", width: "20%" },
        { text: "Event", value: "description", width: "25%" },
        { text: "Project", value: "project", width: "20%" },
        { text: "Project ID", value: "project_id", width: "10%" },
      ],
      activities: [],
    };
  },
  computed: {
    translatedHeaders() {
      return this.headers.map((header) => ({
        ...header,
        text: !["project_id"].includes(header.value)
          ? this.$t(
              `activities.headers.${header.value === "description" ? "event" : header.value}`,
            )
          : "",
      }));
    },
    filteredItems() {
      const searchLower = this.search.toLowerCase();
      return this.activities.filter((item) => {
        return (
          item.creator.toLowerCase().includes(searchLower) ||
          item.date.toLowerCase().includes(searchLower) ||
          item.project.toLowerCase().includes(searchLower) ||
          this.$t(
            `history.elements.details.timelines.${item.event.replace(/\s/g, "")}`,
          )
            .toLowerCase()
            .includes(searchLower)
        );
      });
    },
  },
  methods: {
    async getActivities() {
      this.loading = true;
      try {
        const response = await activities.fetchActivities();
        if (response.code == 200) {
          return response.data.activity.map((activity) => ({
            ...activity,
            date: moment(activity.date).format("MM / DD / YYYY"),
          }));
        } else {
          return [];
        }
      } catch (error) {
        return [];
      } finally {
        this.loading = false;
      }
    },
  },
  async activated() {
    this.activities = await this.getActivities();
  },
};
</script>

<style scoped>
::v-deep table tbody tr td {
  padding: 20px 16px !important;
}

@media (min-width: 768px) {
  ::v-deep table tbody tr td:first-child,
  ::v-deep table thead tr th:first-child {
    padding-left: 3rem !important;
  }
  ::v-deep table tbody tr td:last-child,
  ::v-deep table thead tr th:last-child {
    padding-right: 3rem !important;
  }

  ::v-deep table tbody tr td {
    border: none !important;
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep table thead tr th {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 30px !important;
}
</style>
