<template>
  <v-card class="pa-4 d-flex flex-column" height="100%" elevation="0">
    <v-card-title class="text-h6 font-weight-light pl-0">
      {{ $t("profile.jobInformationCard.jobInformation") }}
    </v-card-title>
    <v-form
      class="mb-0 flex-grow-1 d-flex flex-column"
      v-model="validForm"
      disabled
    >
      <v-row class="flex-grow-1">
        <v-col cols="12" lg="7">
          <v-text-field
            v-model="jobTitle"
            :label="$t('profile.jobInformationCard.jobTitle')"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="entryDate"
            :label="$t('profile.jobInformationCard.entryDate')"
            placeholder=""
            outlined
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="5" class="d-flex justify-end align-end">
          <v-btn
            class="text-case-normal px-6 my-lg-3 edit-button"
            :disabled="true"
            @click="updateJob"
          >
            {{ $t("profile.jobInformationCard.saveChanges") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import user from "@/api/user.js";
import moment from "moment";
export default {
  name: "JobInformationCard",
  props: ["valid"],
  data() {
    return {
      jobTitle: "",
      entryDate: "",
      validForm: true,
    };
  },
  methods: {
    async updateJob() {
      const data = {
        email: this.$store.getters.getUser.email,
        entry_date: this.entryDate,
        job_title: this.jobTitle,
        name: this.$store.getters.getUser.name,
        phone: this.$store.getters.getUser.phone,
        roles: this.$store.getters.getUser.roles,
      };

      this.validForm = false;

      try {
        const response = await user.updateUser(data);
        if (response.code === 200) {
          let dataAlert = {
            message: this.$t("profile.jobInformationCard.jobUpdated"),
            status: "success",
          };
          this.$emit("alert", dataAlert);
          await this.$store.dispatch("authenticateAndLoadUser", false);
        } else {
          let dataAlert = {
            message: this.$t("profile.jobInformationCard.updateFailed"),
            status: "error",
          };
          this.$emit("alert", dataAlert);
        }
        await this.$store.dispatch("updateJob", data);
      } catch (error) {
        let dataAlert = {
          message: this.$t("profile.jobInformationCard.updateFailed"),
          status: "error",
        };
        this.$emit("alert", dataAlert);
      } finally {
        this.validForm = true;
      }
    },
  },
  mounted() {
    this.entryDate = moment(this.$store.getters.getUser.entry_date).format(
      "MM / DD / YYYY",
    );
    this.jobTitle = this.$store.getters.getUser.job_title;
  },
};
</script>
