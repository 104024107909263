<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    style="height: 100%"
  >
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-height="98%"
      min-width="95%"
      elevation="0"
    >
      <p v-if="!isProcessFinished" class="mb-8" style="font-size: 20px">
        {{ $t("team.createMember.newMember") }}
      </p>
      <v-form
        v-if="!isProcessFinished"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="name"
          :rules="nameRules"
          :label="$t('team.createMember.name')"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          :label="$t('team.createMember.email')"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          :label="$t('team.createMember.phoneNumber')"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          :label="$t('team.createMember.password')"
          outlined
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="confirmationPassword"
          :rules="confirmationPasswordRules"
          :label="$t('team.createMember.confirmationPassword')"
          outlined
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="date"
          :rules="dateRules"
          :label="$t('team.createMember.entryDate')"
          type="date"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="jobTitle"
          :rules="jobTitleRules"
          :label="$t('team.createMember.jobTitle')"
          outlined
        ></v-text-field>
        <div
          class="d-flex flex-column justify-center align-center"
          style="width: 100%"
        >
          <v-btn @click="register" :disabled="!valid" class="edit-button mt-10">
            {{ $t("team.createMember.confirmAccount") }}
          </v-btn>
        </div>
      </v-form>
      <div
        style="width: 420px; max-width: 90%"
        class="d-flex flex-column justify-center align-center text-center"
        v-else
      >
        <v-img
          v-if="isProcessOk"
          class="mb-6"
          src="@/assets/aproved.png"
        ></v-img>
        <v-img v-else class="mb-6" src="@/assets/failed.png"></v-img>

        <div v-if="isProcessOk">
          <p>{{ $t("team.createMember.accountCreated") }}</p>
          <v-btn class="edit-button" @click="isProcessFinished = false">
            {{ $t("team.createMember.createAnotherAccount") }}
          </v-btn>

          <v-btn
            v-if="$route.query.company_id"
            @click="resetAndNavigate('Companies')"
            class="edit-button mt-5"
          >
            {{ $t("team.createMember.viewCompanies") }}
          </v-btn>

          <v-btn
            v-else
            @click="resetAndNavigate('Team')"
            class="edit-button mt-5"
          >
            {{ $t("team.createMember.viewTeam") }}
          </v-btn>
        </div>

        <div v-else>
          <p>{{ $t("team.createMember.accountCreationFailed") }}</p>
          <v-btn class="edit-button" @click="isProcessFinished = false">
            {{ $t("team.createMember.tryAgain") }}
          </v-btn>

          <v-btn
            v-if="$route.query.company_id"
            @click="resetAndNavigate('Companies')"
            class="edit-button mt-5"
          >
            {{ $t("team.createMember.viewCompanies") }}
          </v-btn>

          <v-btn
            v-else
            @click="resetAndNavigate('Team')"
            class="edit-button mt-5"
          >
            {{ $t("team.createMember.viewTeam") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import user from "@/api/user";
export default {
  name: "RegisterMemberTeam",
  data: () => ({
    valid: true,
    isProcessFinished: false,
    isProcessOk: true,
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmationPassword: "",
    date: "",
    jobTitle: "",
    nameRules: [],
    emailRules: [],
    phoneRules: [],
    passwordRules: [],
    confirmationPasswordRules: [],
    dateRules: [],
    jobTitleRules: [],
  }),
  watch: {
    "$i18n.locale": {
      handler() {
        this.setValidationRules();
        this.validateFormFields();
      },
      immediate: true,
    },
  },
  methods: {
    setValidationRules() {
      this.nameRules = [
        (v) => !!v || this.$t("team.createMember.validation.name.required"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("team.createMember.validation.name.minLength"),
      ];
      this.emailRules = [
        (v) => !!v || this.$t("team.createMember.validation.email.required"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t("team.createMember.validation.email.valid"),
      ];
      this.phoneRules = [
        (v) => !!v || this.$t("team.createMember.validation.phone.required"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("team.createMember.validation.phone.minLength"),
      ];
      this.passwordRules = [
        (v) => !!v || this.$t("team.createMember.validation.password.required"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("team.createMember.validation.password.minLength"),
      ];
      this.confirmationPasswordRules = [
        (v) =>
          !!v ||
          this.$t("team.createMember.validation.confirmationPassword.required"),
        (v) =>
          v === this.password ||
          this.$t("team.createMember.validation.confirmationPassword.match"),
      ];
      this.dateRules = [
        (v) => !!v || this.$t("team.createMember.validation.date.required"),
      ];
      this.jobTitleRules = [
        (v) => !!v || this.$t("team.createMember.validation.jobTitle.required"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("team.createMember.validation.jobTitle.minLength"),
      ];
    },
    validateFormFields() {
      if (this.$refs.form) {
        // Get the form fields
        const fields = this.$refs.form.$children;
        for (let field of fields) {
          if (field.lazyValue) {
            field.validate();
          }
        }
      }
    },
    async register() {
      if (this.$refs.form.validate()) {
        this.valid = false;
        try {
          const data = {
            email: this.email,
            entry_date: this.date,
            job_title: this.jobTitle,
            name: this.name,
            phone: this.phone,
            password: this.password,
            company_id: this.$route.query.company_id ?? "",
          };
          const response = await user.createUser(data);
          if (response.code == 200) {
            this.isProcessFinished = true;
            this.isProcessOk = true;
            this.$refs.form.reset();
          } else {
            this.isProcessFinished = true;
            this.isProcessOk = false;
            this.$refs.form.reset();
          }
        } catch (error) {
          this.isProcessFinished = true;
          this.isProcessOk = false;
          this.$refs.form.reset();
        }
      }
    },
    resetAndNavigate(routeName) {
      // Reset the form and component state
      this.valid = true;
      this.isProcessFinished = false;
      this.isProcessOk = true;
      this.name = "";
      this.email = "";
      this.phone = "";
      this.password = "";
      this.confirmationPassword = "";
      this.date = "";
      this.jobTitle = "";
      // Navigate to the specified route
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped>
form {
  width: 90%;
}

@media (min-width: 768px) {
  form {
    width: 40%;
  }
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  width: 90% !important;
}
</style>
