import config from "./index";

async function fetchCompanies() {
  const response = await fetch(`${config.apiUrl}/v1/company`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
  });
  const data = await response.json();
  return data;
}

async function createCompany(companyInformation) {
  const response = await fetch(`${config.apiUrl}/v1/company`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(companyInformation),
  });
  const data = await response.json();
  return data;
}

async function updateCompany(companyInformation) {
  const response = await fetch(`${config.apiUrl}/v1/company`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(companyInformation),
  });
  const data = await response.json();
  return data;
}

export default {
  fetchCompanies,
  createCompany,
  updateCompany,
};
