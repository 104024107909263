<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    style="height: 100%"
  >
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-width="95%"
      elevation="0"
    >
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-title>{{
            $t("history.principalHistory.editName")
          }}</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="currentProject.name"
                :rules="nameRules"
                :label="$t('history.principalHistory.headers.name')"
                outlined
              ></v-text-field>
            </v-form>
            <v-alert v-if="error" type="error" dense text>
              {{ $t("history.principalHistory.errorOccurred") }}
            </v-alert>
            <v-alert v-if="success" type="success" dense text>
              {{ $t("history.principalHistory.projectUpdated") }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              {{ $t("history.principalHistory.close") }}
            </v-btn>
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="saveProject"
            >
              {{ $t("history.principalHistory.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-title style="width: 100%" class="px-10 py-7">
        <v-row>
          <v-col class="d-flex justify-start align-center" cols="12" sm="8">
            <v-chip-group
              active-class="primary--text"
              v-model="selection"
              column
            >
              <v-chip class="chip" outlined>{{
                $t("history.principalHistory.all")
              }}</v-chip>
              <v-chip class="chip" outlined>{{
                $t("history.principalHistory.pending")
              }}</v-chip>
              <v-chip class="chip" outlined>{{
                $t("history.principalHistory.rejected")
              }}</v-chip>
              <v-chip class="chip" outlined>{{
                $t("history.principalHistory.incomplete")
              }}</v-chip>
              <v-chip class="chip" outlined>{{
                $t("history.principalHistory.status")
              }}</v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              class="d-flex justify-center align-center"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('history.principalHistory.search')"
              filled
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        style="width: 100%"
        height="75vh"
        :headers="translatedHeaders"
        :items="filteredProjects"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        mobile-breakpoint="768"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.LOR`]="{ item }">
          <span :style="{ color: getColorLor(item.LOR) }">{{
            $t(`history.elements.forms.${item.LOR}`)
          }}</span>
        </template>
        <template v-slot:[`item.intake`]="{ item }">
          <v-chip :color="getColor(item)" :text-color="getTextColor(item)" dark>
            {{
              $t(
                `history.elements.config.intakeOptions.${item.intake.toLowerCase()}`,
              )
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span :style="{ color: getColorStatus(item.status) }">
            {{
              $t(
                `history.elements.config.statusOptions.${item.status.toLowerCase()}`,
              )
            }}
          </span>
        </template>
        <template v-slot:[`item.project_scope`]="{ item }">
          <div class="scope-container-images">
            <v-img
              v-if="item.project_scope.scope"
              class="scope-images"
              src="@/assets/projectfullview/scope/house_on.svg"
            ></v-img>
            <v-img
              v-else
              class="scope-images"
              src="@/assets/projectfullview/scope/house_off.svg"
            ></v-img>
            <v-img
              v-if="item.project_scope.files"
              class="scope-images"
              src="@/assets/projectfullview/scope/folder_on.svg"
            ></v-img>
            <v-img
              v-else
              class="scope-images"
              src="@/assets/projectfullview/scope/folder_off.svg"
            ></v-img>
            <v-img
              v-if="item.project_scope.documents"
              class="scope-images"
              src="@/assets/projectfullview/scope/note_on.svg"
            ></v-img>
            <v-img
              v-else
              class="scope-images"
              src="@/assets/projectfullview/scope/note_off.svg"
            ></v-img>
          </div>

          <p style="display: none">{{ item.project_scope }}</p>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <div class="d-flex justify-center align-center">
            <v-btn class="edit-button mr-2" @click="openEditDialog(item)">
              {{ $t("history.principalHistory.editName") }}
            </v-btn>
            <v-badge
              :content="item.unseen"
              :value="item.unseen"
              color="#2b58e2"
              overlap
              bordered
            >
              <v-btn
                class="edit-button"
                @click="
                  $router.push({
                    name: 'HistoryFullView',
                    params: { id: item.id },
                  })
                "
              >
                {{ $t("history.principalHistory.fullView") }}
              </v-btn>
            </v-badge>
          </div>
        </template>
      </v-data-table>
      <div class="text-center py-5">
        <v-pagination
          total-visible="5"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import history from "@/api/history";
import project from "@/api/project";
import moment from "moment";
export default {
  name: "History",
  data() {
    return {
      dialog: false,
      currentProject: {},
      nameRules: [
        (v) =>
          !!v || this.$t("history.principalHistory.validation.name.required"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("history.principalHistory.validation.name.minLength"),
      ],
      error: false,
      success: false,
      valid: true,
      selection: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      headers: [
        { text: "SUB#", value: "number", width: "10%" },
        { text: "Name", value: "name", width: "10%" },
        { text: "LOR", value: "LOR", width: "10%" },
        { text: "Intake", value: "intake", width: "10%" },
        { text: "Status", value: "status", width: "10%" },
        { text: "Address", value: "address", width: "15%" },
        { text: "Created Date", value: "created_at", width: "15%" },
        { text: "Company", value: "company_name", width: "10%" },
        { text: "", value: "id", width: "10%", sortable: false },
      ],
      projects: [],
    };
  },
  computed: {
    filteredProjects() {
      const searchLower = this.search.toLowerCase();
      const filtered = this.projects.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchLower) ||
          item.address.toLowerCase().includes(searchLower) ||
          item.created_at.toLowerCase().includes(searchLower) ||
          this.$t(`history.elements.forms.${item.LOR}`)
            .toLowerCase()
            .includes(searchLower) ||
          this.$t(
            `history.elements.config.intakeOptions.${item.intake.toLowerCase()}`,
          )
            .toLowerCase()
            .includes(searchLower) ||
          this.$t(
            `history.elements.config.statusOptions.${item.status.toLowerCase()}`,
          )
            .toLowerCase()
            .includes(searchLower) ||
          item.company_name.toLowerCase().includes(searchLower)
        );
      });

      const pending = this.$t("history.elements.config.intakeOptions.pending");
      const rejected = this.$t(
        "history.elements.config.intakeOptions.rejected",
      );
      const open = this.$t("history.elements.config.statusOptions.open");
      const incomplete = this.$t("history.principalHistory.incomplete");

      return filtered.filter((project) => {
        let intake = this.$t(
          `history.elements.config.intakeOptions.${project.intake.toLowerCase()}`,
        );

        let status = this.$t(
          `history.elements.config.statusOptions.${project.status.toLowerCase()}`,
        );

        if (this.selection === 0) return true;
        if (this.selection === 1) return intake === pending;
        if (this.selection === 2) return intake === rejected;
        if (this.selection === 3) return intake === incomplete;
        if (this.selection === 4) return status === open;
      });
    },
    translatedHeaders() {
      return this.headers.map((header) => ({
        ...header,
        text:
          header.value !== "id"
            ? this.$t(`history.principalHistory.headers.${header.value}`)
            : "",
      }));
    },
  },
  methods: {
    async getProjects() {
      try {
        const companyId = this.$route.query.company_id ?? "";
        const response = await history.fetchHistory(companyId);
        if (response.code == 200) {
          return response.data.history.map((project) => ({
            ...project,
            created_at: moment(project.created_at).format("MM / DD / YYYY"),
          }));
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    },
    getColor(item) {
      if (item.intake === "Approved") {
        return "#C9F0D3";
      } else if (item.intake === "Rejected") {
        return "#F8D7DA";
      } else if (item.intake === "Incomplete") {
        return "#ffeecc";
      } else {
        return "#F0F0F0";
      }
    },
    getTextColor(item) {
      if (item.intake === "Approved") {
        return "#399652";
      } else if (item.intake === "Rejected") {
        return "#DD5555";
      } else if (item.intake === "Incomplete") {
        return "#ffc142";
      } else {
        return "#000";
      }
    },
    getColorLor(value) {
      if (value === "Signed") {
        return "#2a58e2";
      } else {
        return "#DD5555";
      }
    },
    getColorStatus(value) {
      if (value === "Open") {
        return "#2a58e2";
      } else if (value === "Closed") {
        return "#DD5555";
      }
    },
    openEditDialog(project) {
      this.currentProject = { ...project };
      this.dialog = true;
    },
    async saveProject() {
      if (this.$refs.form.validate()) {
        this.success = false;
        this.error = false;
        this.valid = false;

        try {
          const data = {
            name: this.currentProject.name,
            project_id: this.currentProject.id,
          };
          const response = await project.updateProject(data);
          if (response.code == 200) {
            this.projects = await this.getProjects();
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.valid = true;
              this.dialog = false;
            }, 3000);
          } else {
            this.error = true;
          }
        } catch (error) {
          this.error = true;
        }
      }
    },
  },
  async activated() {
    this.projects = await this.getProjects();
    const status = this.$route.query.status;
    if (status) {
      this.selection = status;
    }
  },
  watch: {
    "$i18n.locale"() {
      this.headers = this.translatedHeaders;
    },
  },
};
</script>

<style scoped>
@media (min-width: 760px) {
  .chip {
    min-width: 85px !important;
    display: flex;
    justify-content: center;
  }
}
::v-deep table tbody tr td {
  padding: 20px 16px !important;
}

@media (min-width: 768px) {
  ::v-deep table tbody tr td:first-child,
  ::v-deep table thead tr th:first-child {
    padding-left: 3rem !important;
  }
  ::v-deep table tbody tr td:last-child,
  ::v-deep table thead tr th:last-child {
    padding-right: 3rem !important;
  }

  ::v-deep table tbody tr td {
    border: none !important;
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep table thead tr th {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 30px !important;
}

.scope-container-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}

.scope-images {
  width: 18px;
  height: 18px;
}
</style>
