<template>
  <v-container
    fluid
    class="d-flex flex-column justify-start align-center"
    style="height: 100%"
  >
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <DashboardHeader v-if="!loading" :details="details" />
    <DashboardTable v-if="!loading" :projects="details.history" />
    <v-divider v-if="!loading"></v-divider>
    <Activities v-if="!loading" :activities="details.activity" />
  </v-container>
</template>

<script>
import DashboardHeader from "./DashboardHeader.vue";
import DashboardTable from "./DashboardTable.vue";
import Activities from "./Activities.vue";
import dashboard from "@/api/dashboard";
export default {
  name: "Dashboard",
  components: { DashboardHeader, DashboardTable, Activities },
  data() {
    return {
      loading: true,
      details: {},
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const response = await dashboard.fetchDashboard();
        if (response.code == 200) {
          this.details = response.data;
          this.loading = false;
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async activated() {
    await this.fetchData();
  },
};
</script>

<style scoped>
.v-divider {
  width: 97% !important;
  margin-top: 1rem !important;
}
</style>
