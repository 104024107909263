var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex flex-column justify-start align-center",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(!_vm.loading)?_c('Header',{attrs:{"details":_vm.details}}):_vm._e(),(!_vm.loading)?_c('div',{staticStyle:{"width":"98%"}},[_c('v-tabs',{attrs:{"center-active":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t("history.fullView.details")))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t("history.fullView.contacts")))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t("history.fullView.files")))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t("history.fullView.forms")))]),(
          ['superuser', 'developer'].includes(_vm.$store.getters.getUser.role)
        )?_c('v-tab',[_vm._v(_vm._s(_vm.$t("history.fullView.config")))]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('Details',{attrs:{"details":_vm.details}})],1),_c('v-tab-item',[_c('Contacts',{attrs:{"contacts":_vm.contacts},on:{"contacts-updated":_vm.handleContactsUpdated}})],1),_c('v-tab-item',[_c('Files',{attrs:{"folders":_vm.files},on:{"file-uploaded":_vm.handleFileUploaded}})],1),_c('v-tab-item',[_c('Forms')],1),(
          ['superuser', 'developer'].includes(_vm.$store.getters.getUser.role)
        )?_c('v-tab-item',[_c('Config',{attrs:{"details":_vm.details},on:{"updateProject":_vm.updateProject}})],1):_vm._e()],1)],1):_vm._e(),_c('v-dialog',{staticStyle:{"z-index":"1003","max-width":"90vw"},model:{value:(_vm.$store.state.modalDocuments.dialogPdfViewer),callback:function ($$v) {_vm.$set(_vm.$store.state.modalDocuments, "dialogPdfViewer", $$v)},expression:"$store.state.modalDocuments.dialogPdfViewer"}},[_c('v-card',[(_vm.$store.getters.getModalDocuments.isPdf)?_c('vue-pdf-app',{staticStyle:{"height":"100vh","width":"90vw"},attrs:{"pdf":_vm.$store.getters.getModalDocuments.documentUrl,"theme":"dark"}}):(_vm.$store.getters.getModalDocuments.isOfficeDocument)?_c('VueDocPreview',{staticStyle:{"height":"100vh","width":"90vw"},attrs:{"value":_vm.$store.getters.getModalDocuments.documentUrl,"type":"office"}}):_c('v-img',{staticStyle:{"height":"80vh","width":"70vw","max-width":"70vw"},attrs:{"src":_vm.getImage(_vm.$store.getters.getModalDocuments.documentUrl),"contain":""}}),(
          !_vm.$store.getters.getModalDocuments.isPdf &&
          !_vm.$store.getters.getModalDocuments.isOfficeDocument
        )?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.downloadDocument}},[_vm._v(" "+_vm._s(_vm.$t("history.fullView.downloadImage"))+" ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }