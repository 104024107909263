var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mx-3 mt-3 mb-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex align-stretch"},[_c('v-col',{staticClass:"my-1",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('v-card',{staticClass:"mx-auto flex-grow-1",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"px-8 py-3"},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("dashboard.header.totalClaims")))]),_c('p',{staticClass:"font-weight-bold text-h4 text-lg-h3 mt-2",staticStyle:{"color":"#433c3c"}},[_vm._v(" "+_vm._s(_vm.details.summary.claims.total)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column justify-start align-end",attrs:{"cols":"2"}},[_c('v-img',{staticStyle:{"margin-top":"-30px"},attrs:{"max-height":"150","max-width":"150","src":require("@/assets/dashboard/clients.svg")}})],1)],1)],1)],1),_c('v-col',{staticClass:"my-1",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('v-card',{staticClass:"mx-auto flex-grow-1",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"px-8 py-3"},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("dashboard.header.intakePending")))]),_c('p',{staticClass:"mt-2",staticStyle:{"color":"#433c3c","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
                  name: 'History',
                  query: { status: 1 },
                })}}},[_c('span',{staticClass:"font-weight-bold text-h4 text-lg-h3"},[_vm._v(_vm._s(_vm.details.summary.intake.pending))]),_vm._v("/"+_vm._s(_vm.details.summary.intake.total)+" ")]),_c('span',{staticClass:"font-weight-light d-block mb-5",staticStyle:{"color":"#433c3c","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
                  name: 'History',
                  query: { status: 2 },
                })}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.header.intakeRejected"))+" "+_vm._s(_vm.details.summary.intake.rejected)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column justify-start align-end",attrs:{"cols":"2"}},[_c('v-img',{staticStyle:{"margin-top":"-30px"},attrs:{"max-height":"150","max-width":"150","src":require("@/assets/dashboard/claim.svg")}})],1)],1)],1)],1),_c('v-col',{staticClass:"my-1",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('v-card',{staticClass:"mx-auto flex-grow-1",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"px-8 py-3"},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("dashboard.header.claimOpen")))]),_c('p',{staticClass:"mt-2",staticStyle:{"color":"#433c3c","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
                  name: 'History',
                  query: { status: 4 },
                })}}},[_c('span',{staticClass:"font-weight-bold text-h4 text-lg-h3"},[_vm._v(_vm._s(_vm.details.summary.claims.open))]),_vm._v("/"+_vm._s(_vm.details.summary.claims.total)+" ")]),_c('span',{staticClass:"font-weight-light d-block mb-5",staticStyle:{"color":"#433c3c"}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.header.claimClosed"))+" "+_vm._s(_vm.details.summary.claims.closed)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column justify-start align-end",attrs:{"cols":"2"}},[_c('v-img',{staticStyle:{"margin-top":"-30px"},attrs:{"max-height":"150","max-width":"150","src":require("@/assets/dashboard/claim.svg")}})],1)],1)],1)],1),_c('v-col',{staticClass:"my-1",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('v-card',{staticClass:"mx-auto flex-grow-1",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"px-8 py-3"},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("dashboard.header.totalWorkers")))]),_c('p',{staticClass:"font-weight-bold text-h4 text-lg-h3 mt-2",staticStyle:{"color":"#433c3c"}},[_vm._v(" "+_vm._s(_vm.details.summary.workers)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column justify-start align-end",attrs:{"cols":"2"}},[_c('v-img',{staticStyle:{"margin-top":"-30px"},attrs:{"max-height":"150","max-width":"150","src":require("@/assets/dashboard/workers.svg")}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }