<template>
  <v-container fluid class="my-3 mx-0 pa-0">
    <p class="mt-5 mb-8 mx-2" style="font-size: 20px">
      {{ $t("history.elements.config.editStatusAndIntake") }}
    </p>
    <v-card class="card-forms" elevation="0" min-height="50vh">
      <v-form ref="form">
        <v-select
          :items="translatedStatusOptions"
          :label="$t('history.elements.config.status')"
          v-model="selectedStatus"
          outlined
        ></v-select>
        <v-select
          :items="translatedIntakeOptions"
          :label="$t('history.elements.config.intake')"
          v-model="selectedIntake"
          outlined
        ></v-select>
        <v-btn
          :disabled="inProgress"
          block
          class="edit-button"
          elevation="0"
          @click="update"
          >{{ $t("history.elements.config.update") }}</v-btn
        >
      </v-form>
      <v-alert class="mt-3" v-if="error" type="error" dense text>
        {{ $t("history.elements.config.error") }}
      </v-alert>
      <v-alert class="mt-3" v-if="itsOk" type="success" dense text>
        {{ $t("history.elements.config.success") }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import fullProject from "@/api/fullProject";
export default {
  name: "Config",
  props: {
    details: Object,
  },
  data() {
    return {
      inProgress: false,
      error: false,
      itsOk: false,
      statusOptions: [
        "Open",
        "Closed",
        "TBD",
        "Inspection",
        "Negotiations",
        "Appraisal",
        "Settled",
        "Depreciation",
      ],
      intakeOptions: ["Approved", "Pending", "Rejected", "Incomplete"],
      selectedStatus: null,
      selectedIntake: null,
    };
  },
  computed: {
    translatedStatusOptions() {
      return this.statusOptions.map((option) => ({
        text: this.$t(
          `history.elements.config.statusOptions.${option.toLowerCase()}`,
        ),
        value: option,
      }));
    },
    translatedIntakeOptions() {
      return this.intakeOptions.map((option) => ({
        text: this.$t(
          `history.elements.config.intakeOptions.${option.toLowerCase()}`,
        ),
        value: option,
      }));
    },
  },
  methods: {
    async update() {
      this.inProgress = true;
      this.error = false;
      this.itsOk = false;
      const data = {
        status: this.selectedStatus,
        intake: this.selectedIntake,
        project_id: this.details.project.id,
      };
      try {
        const response = await fullProject.updateProject(data);
        if (response.code == 200) {
          this.itsOk = true;
          this.$emit("updateProject", response.data.project);
        } else {
          this.error = true;
        }
      } catch (e) {
        this.error = true;
      }
      this.inProgress = false;
    },
  },
  created() {
    this.selectedStatus = this.details.project.status;
    this.selectedIntake = this.details.project.intake;
  },
};
</script>

<style scoped>
.v-form {
  width: 100%;
}

.card-forms {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 35px !important;
}

@media (min-width: 768px) {
  .card-forms {
    width: 50%;
  }
}
</style>
