<template>
  <v-container fluid class="ma-3">
    <v-row class="d-flex align-stretch">
      <v-col class="my-1" cols="12" lg="4">
        <v-card class="mx-auto flex-grow-1" elevation="0">
          <v-row class="px-5 py-2">
            <v-col cols="6">
              <p>{{ $t("history.elements.header.client") }}</p>
              <p class="font-weight-regular text-h5 text-lg-h4">
                {{ details.customer_information.name }}
              </p>
            </v-col>
            <v-col class="d-flex flex-column justify-start align-end" cols="6">
              <v-img
                max-height="92"
                max-width="110"
                src="@/assets/projectfullview/profile.svg"
              ></v-img>
              <p class="px-2 text-center">
                <span style="color: #2b58e2">
                  {{ $t("history.elements.header.subNumber") }}
                </span>
                <span class="d-block">
                  {{ details.project.company_name }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="my-1" cols="12" lg="4">
        <v-card class="mx-auto flex-grow-1" elevation="0">
          <v-row class="px-5 py-2">
            <v-col cols="9">
              <p class="mb-0">
                {{ $t("history.elements.header.claimStatus") }}
              </p>
              <p
                style="color: #2b58e2"
                class="font-weight-regular text-h5 text-lg-h4"
              >
                {{ details.project.status }}
              </p>
              <p class="mt-6">{{ formatDate(details.project.created_at) }}</p>
            </v-col>
            <v-col class="d-flex flex-column justify-start align-end" cols="3">
              <v-img
                max-height="92"
                max-width="110"
                src="@/assets/projectfullview/house.svg"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="my-1" cols="12" lg="4">
        <v-card class="mx-auto flex-grow-1" elevation="0">
          <v-row class="px-5 py-2">
            <v-col cols="9">
              <p class="mb-0">
                {{ $t("history.elements.header.totalPayments") }}
              </p>
              <p
                style="color: #399652"
                class="font-weight-regular text-h5 text-lg-h4"
              >
                {{ details.payments.total_payments }}
              </p>
              <p class="mt-6">
                {{ formatDatePayment(details.payments.last_payment) }}
              </p>
            </v-col>
            <v-col class="d-flex flex-column justify-start align-end" cols="3">
              <v-img
                max-height="92"
                max-width="110"
                src="@/assets/projectfullview/dolar.svg"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "HeaderProjectFullView",
  data() {
    return {
      tab: null,
    };
  },
  props: {
    details: Object,
  },
  methods: {
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const now = moment();
      const diff = now.diff(date, "days");
      const hours = now.diff(date, "hours") % 24;
      const formattedDate = date.format("MM / DD / YYYY");
      return `${diff} Days ${hours} hours ago - ${formattedDate}`;
    },

    formatDatePayment(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const formattedDate = date.format("MM / DD / YYYY");
      return `${this.$t("history.elements.header.lastPayment")} ${formattedDate}`;
    },
  },
};
</script>

<style scoped>
p {
  font-size: 19px;
}

.text-lg-h4 {
  font-size: 28px !important;
}

.v-card {
  height: 100%;
}
</style>
