<template>
  <v-container fluid class="mx-3 mt-3 mb-1">
    <v-row class="d-flex align-stretch">
      <v-col class="my-1" cols="12" md="6" xl="3">
        <v-card class="mx-auto flex-grow-1" elevation="0">
          <v-row class="px-8 py-3">
            <v-col cols="10">
              <p class="mb-0">{{ $t("dashboard.header.totalClaims") }}</p>
              <p
                style="color: #433c3c"
                class="font-weight-bold text-h4 text-lg-h3 mt-2"
              >
                {{ details.summary.claims.total }}
              </p>
            </v-col>
            <v-col class="d-flex flex-column justify-start align-end" cols="2">
              <v-img
                max-height="150"
                max-width="150"
                src="@/assets/dashboard/clients.svg"
                style="margin-top: -30px"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="my-1" cols="12" md="6" xl="3">
        <v-card class="mx-auto flex-grow-1" elevation="0">
          <v-row class="px-8 py-3">
            <v-col cols="10">
              <p class="mb-0">{{ $t("dashboard.header.intakePending") }}</p>
              <p
                style="color: #433c3c; cursor: pointer"
                class="mt-2"
                @click="
                  $router.push({
                    name: 'History',
                    query: { status: 1 },
                  })
                "
              >
                <span class="font-weight-bold text-h4 text-lg-h3">{{
                  details.summary.intake.pending
                }}</span
                >/{{ details.summary.intake.total }}
              </p>
              <span
                class="font-weight-light d-block mb-5"
                style="color: #433c3c; cursor: pointer"
                @click="
                  $router.push({
                    name: 'History',
                    query: { status: 2 },
                  })
                "
              >
                {{ $t("dashboard.header.intakeRejected") }}
                {{ details.summary.intake.rejected }}
              </span>
            </v-col>
            <v-col class="d-flex flex-column justify-start align-end" cols="2">
              <v-img
                max-height="150"
                max-width="150"
                src="@/assets/dashboard/claim.svg"
                style="margin-top: -30px"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="my-1" cols="12" md="6" xl="3">
        <v-card class="mx-auto flex-grow-1" elevation="0">
          <v-row class="px-8 py-3">
            <v-col cols="10">
              <p class="mb-0">{{ $t("dashboard.header.claimOpen") }}</p>
              <p
                style="color: #433c3c; cursor: pointer"
                class="mt-2"
                @click="
                  $router.push({
                    name: 'History',
                    query: { status: 4 },
                  })
                "
              >
                <span class="font-weight-bold text-h4 text-lg-h3">{{
                  details.summary.claims.open
                }}</span
                >/{{ details.summary.claims.total }}
              </p>
              <span
                class="font-weight-light d-block mb-5"
                style="color: #433c3c"
              >
                {{ $t("dashboard.header.claimClosed") }}
                {{ details.summary.claims.closed }}
              </span>
            </v-col>
            <v-col class="d-flex flex-column justify-start align-end" cols="2">
              <v-img
                max-height="150"
                max-width="150"
                src="@/assets/dashboard/claim.svg"
                style="margin-top: -30px"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="my-1" cols="12" md="6" xl="3">
        <v-card class="mx-auto flex-grow-1" elevation="0">
          <v-row class="px-8 py-3">
            <v-col cols="10">
              <p class="mb-0">{{ $t("dashboard.header.totalWorkers") }}</p>
              <p
                style="color: #433c3c"
                class="font-weight-bold text-h4 text-lg-h3 mt-2"
              >
                {{ details.summary.workers }}
              </p>
            </v-col>
            <v-col class="d-flex flex-column justify-start align-end" cols="2">
              <v-img
                max-height="150"
                max-width="150"
                src="@/assets/dashboard/workers.svg"
                style="margin-top: -30px"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DashboardHeader",
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
p {
  font-size: 19px;
}

.text-lg-h4 {
  font-size: 28px !important;
}

.v-card {
  height: 100%;
}
</style>
