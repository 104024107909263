<template>
  <v-container fluid class="mx-3">
    <p class="mt-2 mb-8 mx-2" style="font-size: 20px">
      {{ $t("dashboard.activities.title") }}
    </p>
    <v-slide-group>
      <v-slide-item
        style="cursor: pointer"
        v-for="(activity, index) in activities"
        :key="index"
      >
        <v-card
          @click="
            $router.push({
              name: 'HistoryFullView',
              params: { id: activity.project_id },
            })
          "
          elevation="0"
          class="mr-5 flex-grow-1"
          width="320"
          style="max-height: 140px"
        >
          <v-row class="px-2 py-3 d-flex justify-center align-center" dense>
            <v-col class="d-flex justify-center align-center" cols="3">
              <v-img
                max-height="50"
                max-width="50"
                :src="getImagePath(activity.event)"
              ></v-img>
            </v-col>
            <v-col class="pa-0" cols="1">
              <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="8">
              <p>
                {{ $t("dashboard.activities.newUpdate") }}
                <span class="text-body-2 color-disabled"
                  >- {{ formatDate(activity.date) }}</span
                >
              </p>
              <p class="text-body-1 color-disabled">
                <span style="text-decoration: underline">{{
                  activity.creator
                }}</span>
                {{
                  $t(
                    `history.elements.details.timelines.${activity.event.replace(/\s/g, "")}`,
                  )
                }}
              </p>
              <p class="text-body-1">
                <span class="color-disabled">{{
                  $t("dashboard.activities.at")
                }}</span>
                {{ activity.project }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "Activities",
  props: {
    activities: Array,
  },
  data() {
    return {};
  },
  methods: {
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const formattedDate = date.format("MM / DD / YYYY");
      return formattedDate;
    },
    getImagePath(event) {
      const eventToImagePath = {
        "Claim Created": require("@/assets/dashboard/claim.svg"),
        "File Uploaded": require("@/assets/dashboard/files.svg"),
        "Contacts Updated": require("@/assets/dashboard/workers.svg"),
        Comments: require("@/assets/dashboard/comment.svg"),
        Payment: require("@/assets/dashboard/payment.svg"),
      };
      return eventToImagePath[event] || require("@/assets/dashboard/claim.svg");
    },
  },
};
</script>

<style scoped>
::v-deep .v-slide-group__prev--disabled {
  display: none !important;
}

.v-divider {
  border: 1px solid #ffc041;
  height: 90px;
}

.color-disabled {
  color: #a09d9d;
}
p {
  margin: 2px 0;
}
</style>
