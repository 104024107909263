<template>
  <v-container class="login-form-container">
    <img style="max-width: 80%" src="@/assets/logo.svg" alt="Logo" />

    <p class="text-center mt-10 mb-6" style="font-size: 30px; line-height: 1.2">
      {{ $t("login.interest.top") }}
      <br />
      {{ $t("login.interest.bottom") }}
    </p>

    <v-card-text class="text-center" v-if="showLoginForm">
      <v-form ref="form" v-model="valid" lazy-validation @submit="login">
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          :label="$t('login.email')"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="[rules.required]"
          :label="$t('login.password')"
          outlined
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <div class="d-flex justify-space-between align-center">
          <v-checkbox
            v-model="rememberMe"
            :label="$t('login.rememberMe')"
          ></v-checkbox>
          <p @click="toggleForm" class="my-0 ml-2 recovery">
            {{ $t("login.forgotPassword") }}
          </p>
        </div>
        <v-alert v-if="loginError" type="error" dense text>
          {{ loginError }}
        </v-alert>
        <v-btn
          type="submit"
          class="login-button"
          :disabled="!valid"
          x-large
          block
        >
          {{ $t("login.signIn") }}
        </v-btn>
        <a
          href="https://form.jotform.com/241765107770155"
          target="_blank"
          class="mt-3 d-block v-btn recovery register"
        >
          {{ $t("login.register") }}
        </a>
      </v-form>
    </v-card-text>

    <v-card-text v-else>
      <v-form
        ref="resetForm"
        v-model="validReset"
        lazy-validation
        @submit="resetPassword"
      >
        <v-text-field
          v-model="resetEmail"
          :rules="[rules.required, rules.email]"
          :label="$t('login.email')"
          outlined
        ></v-text-field>
        <v-alert v-if="resetError" type="error" dense text>
          {{ resetError }}
        </v-alert>
        <v-alert v-if="showSuccessAlert" type="success" dense text>
          {{ $t("login.resetSuccess") }}
        </v-alert>
        <v-btn
          type="submit"
          class="login-button"
          :disabled="!validReset"
          x-large
          block
        >
          {{ $t("login.resetPassword") }}
        </v-btn>
        <v-btn class="mt-5" text @click="toggleForm" block>
          {{ $t("login.backToLogin") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-container>
</template>

<script>
import user from "@/api/user.js";
export default {
  name: "LoginForm",
  data() {
    return {
      valid: true,
      validReset: true,
      email: "",
      password: "",
      rememberMe: false,
      showPassword: false,
      loginErrorKey: "",
      resetEmail: "",
      resetError: "",
      showLoginForm: true,
      showSuccessAlert: false, // Nueva propiedad para controlar la visibilidad de la alerta de éxito
      rules: {
        required: (value) => !!value || this.$t("login.required"),
        email: (value) =>
          /.+@.+\..+/.test(value) || this.$t("login.invalidEmail"),
      },
    };
  },
  computed: {
    loginError() {
      return this.$t(this.loginErrorKey);
    },
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
      this.loginErrorKey = "";
      this.resetError = "";
      this.resetForms();
    },
    resetForms() {
      // Reset login form
      this.email = "";
      this.password = "";
      // Reset reset password form
      this.resetEmail = "";
    },
    async login(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        try {
          const data = {
            email: this.email,
            password: this.password,
            remember: this.rememberMe,
          };
          this.valid = false;
          const response = await user.login(data);
          if (response.meta.code === 200) {
            this.valid = true;
            const token = response.response.user.authentication_token;
            localStorage.setItem("authToken", token);
            this.$router.push({ name: "Dashboard" });
          } else {
            this.loginErrorKey = "login.invalidEmailOrPassword";
            this.valid = true;
          }
        } catch (error) {
          this.loginErrorKey = "login.signInFailed";
          this.valid = true;
        }
      }
    },
    async resetPassword(e) {
      e.preventDefault();
      if (this.$refs.resetForm.validate()) {
        try {
          this.validReset = false;
          const response = await user.recovery(this.resetEmail);
          if (response.code === 200) {
            this.validReset = true;
            this.showSuccessAlert = true; // Mostrar la alerta de éxito
            setTimeout(() => {
              this.showSuccessAlert = false; // Ocultar la alerta después de 3 segundos
              this.toggleForm();
            }, 3000);
          } else {
            this.resetError = this.$t("login.resetFailed");
            this.validReset = true;
          }
        } catch (error) {
          this.resetError = this.$t("login.resetFailed");
          this.validReset = true;
        }
      }
    },
  },
  watch: {
    "$i18n.locale"() {
      // Validate form when language changes
      if (this.$refs.form) {
        // Get the form fields
        const fields = this.$refs.form.$children;
        for (let field of fields) {
          if (field.lazyValue) {
            field.validate();
          }
        }
      }

      if (this.$refs.resetForm) {
        const fields = this.$refs.resetForm.$children;
        for (let field of fields) {
          if (field.lazyValue) {
            field.validate();
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.login-form-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  height: 100%;
  padding: 15% 5%;
}

.recovery {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-size: 16px;
}

@media (min-width: 992px) {
  .login-form-container {
    padding: 15%;
  }
}

.login-button {
  background-color: #2b2c30 !important;
  color: white !important;
}

button {
  text-transform: none;
  font-family: "Roboto", sans-serif;
}

.register {
  text-transform: none;
  padding: 12px;
  border: solid 1px #2b2c30;
}

.login-button:disabled {
  background-color: grey !important;
}

@media (min-width: 768px) {
  .login-form-container {
    border-radius: 0px;
  }
}
</style>
