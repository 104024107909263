<template>
  <v-card class="pa-4 d-flex flex-column" height="100%" elevation="0">
    <v-card-title class="text-h6 font-weight-light pl-0">
      {{ $t("profile.userPasswordCard.updatePassword") }}
    </v-card-title>
    <v-form
      ref="form"
      class="mb-0 flex-grow-1 d-flex flex-column"
      v-model="validForm"
      :disabled="!valid"
    >
      <v-row class="flex-grow-1">
        <v-col cols="12" lg="7">
          <v-text-field
            :label="$t('profile.userPasswordCard.currentPassword')"
            v-model="currentPassword"
            :rules="passwordRules"
            :placeholder="$t('profile.userPasswordCard.currentPassword')"
            outlined
            type="password"
          ></v-text-field>
          <v-text-field
            :label="$t('profile.userPasswordCard.newPassword')"
            v-model="newPassword"
            :rules="passwordRules"
            :placeholder="$t('profile.userPasswordCard.newPassword')"
            outlined
            type="password"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="5" class="d-flex justify-end align-end">
          <v-btn
            class="text-case-normal px-6 my-lg-3 edit-button"
            :disabled="!valid || !validForm"
            @click="updatePassword"
          >
            {{ $t("profile.userPasswordCard.saveChanges") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import user from "@/api/user.js";
export default {
  name: "PasswordResetCard",
  props: ["valid"],
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      validForm: true,
      passwordRules: [
        (v) => !!v || this.$t("profile.userPasswordCard.passwordRequired"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("profile.userPasswordCard.passwordMinLength"),
      ],
    };
  },
  methods: {
    async updatePassword() {
      try {
        const data = {
          email: this.$store.getters.getUser.email,
          entry_date: this.$store.getters.getUser.entry_date,
          job_title: this.$store.getters.getUser.job_title,
          name: this.$store.getters.getUser.name,
          phone: this.$store.getters.getUser.phone,
          roles: this.$store.getters.getUser.roles,
          password: this.currentPassword,
          new_password: this.newPassword,
        };
        this.validForm = false;

        const response = await user.updateUser(data);
        if (response.code === 200) {
          let dataAlert = {
            message: this.$t("profile.userPasswordCard.passwordUpdated"),
            status: "success",
          };
          this.$emit("alert", dataAlert);
        } else {
          let dataAlert = {
            message: this.$t("profile.userPasswordCard.passwordUpdateFailed"),
            status: "error",
          };
          this.$emit("alert", dataAlert);
        }
      } catch (error) {
        let dataAlert = {
          message: this.$t("profile.userPasswordCard.generalUpdateFailed"),
          status: "error",
        };
        this.$emit("alert", dataAlert);
      } finally {
        this.validForm = true;
        this.$refs.form.reset();
      }
    },
  },
};
</script>
