import config from "./index";

async function fetchTeam() {
  const response = await fetch(`${config.apiUrl}/v1/team`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
  });
  const data = await response.json();
  return data;
}

async function updateTeamMember(userTeamInformation) {
  const response = await fetch(`${config.apiUrl}/v1/team`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(userTeamInformation),
  });
  const data = await response.json();
  return data;
}

async function fetchContact(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/contacts?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function updateContact(contactInformation) {
  const response = await fetch(`${config.apiUrl}/v1/project/contacts`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(contactInformation),
  });
  const data = await response.json();
  return data;
}

export default {
  fetchTeam,
  updateTeamMember,
  fetchContact,
  updateContact,
};
