import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePdfApp from "vue-pdf-app";
import i18n from "./dict";

Vue.config.productionTip = false;
Vue.component("vue-pdf-app", VuePdfApp);
import "vue-pdf-app/dist/icons/main.css";

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
