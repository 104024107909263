<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    style="height: 100%"
  >
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-width="95%"
      elevation="0"
    >
      <v-card-title style="width: 100%" class="px-10 py-7">
        <v-row>
          <v-col cols="4">
            <v-text-field
              class="d-flex justify-center align-center"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('companies.search')"
              filled
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-end align-center" cols="8">
            <v-btn
              small
              elevation="0"
              class="mx-2"
              fab
              dark
              color="yellow darken-2"
              @click="openDialog"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
            <span class="font-weight-light text-subtitle-1 mr-lg-10">
              {{ $t("companies.newCompany") }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- Dialog -->
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("companies.newCompany") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newCompany.name"
                    :rules="nameRules"
                    :label="$t('companies.name')"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="newCompany.email"
                    :rules="emailRules"
                    :label="$t('companies.email')"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="newCompany.address"
                    :rules="addressRules"
                    :label="$t('companies.address')"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="newCompany.phone_number"
                    :rules="phoneRules"
                    :label="$t('companies.phoneNumber')"
                    outlined
                  ></v-text-field>
                  <v-select
                    :items="typesOptions"
                    :label="$t('companies.types')"
                    v-model="newCompany.type"
                    outlined
                  ></v-select>
                  <v-select
                    :items="sellersOptions"
                    :label="$t('companies.sellers')"
                    v-model="newCompany.seller"
                    outlined
                  ></v-select>
                  <v-textarea
                    :label="$t('companies.notes')"
                    outlined
                    v-model="newCompany.notes"
                    rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-alert v-if="error" type="error" dense text>
                {{ $t("companies.errorOccurred") }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              {{ $t("companies.close") }}
            </v-btn>
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              text
              @click="createCompany"
            >
              {{ $t("companies.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End Dialog -->
      <v-data-table
        style="width: 100%"
        height="75vh"
        :loading="loading"
        :headers="translatedHeaders"
        :items="filteredItems"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        mobile-breakpoint="768"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.company_type`]="{ item }">
          <span>{{
            $t(`companies.typesOptions.${item.company_type.toLowerCase()}`)
          }}</span>
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <v-btn
            class="edit-button"
            @click="
              $router.push({
                name: 'RegisterMemberTeam',
                query: { company_id: item.id },
              })
            "
          >
            {{ $t("companies.createAdmin") }}
          </v-btn>
        </template>

        <template v-slot:[`item.history`]="{ item }">
          <div class="d-flex justify-center align-center">
            <v-btn class="edit-button mr-2" @click="editCompany(item)">
              {{ $t("companies.editCompany") }}
            </v-btn>
            <v-btn
              class="edit-button"
              @click="
                $router.push({
                  name: 'History',
                  query: { company_id: item.id },
                })
              "
            >
              {{ $t("companies.companyHistory") }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <div class="text-center py-5">
        <v-pagination
          total-visible="5"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import companies from "@/api/companies";
import moment from "moment";
export default {
  name: "Team",
  data() {
    return {
      loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      headers: [
        { text: "Name", value: "name", width: "10%", sortable: false },
        { text: "Email", value: "email", width: "10%", sortable: false },
        { text: "Address", value: "address", width: "10%", sortable: false },
        { text: "Phone", value: "phone_number", width: "10%", sortable: false },
        { text: "Entry", value: "created_at", width: "10%", sortable: false },
        {
          text: "Company Type",
          value: "company_type",
          width: "10%",
          sortable: false,
        },
        { text: "Sellers", value: "sellers", width: "10%", sortable: false },
        { text: "", value: "id", width: "10%", sortable: false },
        { text: "", value: "history", width: "20%", sortable: false },
      ],
      desserts: [],
      dialog: false,
      newCompany: {
        name: "",
        address: "",
        phone_number: "",
        email: "",
        type: "",
        seller: "",
        notes: "",
        types: ["Roofing", "Mitigation", "Remodeling", "Other"],
        sellers: ["1-5", "6-15", "15+"],
        company_id: "",
      },
      error: false,
      nameRules: [
        (v) => !!v || this.$t("companies.validation.name.required"),
        (v) =>
          (v && v.length >= 3) ||
          this.$t("companies.validation.name.minLength"),
      ],
      emailRules: [
        (v) => !!v || this.$t("companies.validation.email.required"),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t("companies.validation.email.valid"),
      ],
      addressRules: [
        (v) => !!v || this.$t("companies.validation.address.required"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("companies.validation.address.minLength"),
      ],
      phoneRules: [
        (v) => !!v || this.$t("companies.validation.phone.required"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("companies.validation.phone.minLength"),
      ],
    };
  },
  computed: {
    translatedHeaders() {
      return this.headers.map((header) => ({
        ...header,
        text: !["id", "history"].includes(header.value)
          ? this.$t(`companies.headers.${header.value}`)
          : "",
      }));
    },
    typesOptions() {
      return [
        {
          text: this.$t("companies.typesOptions.roofing"),
          value: "Roofing",
        },
        {
          text: this.$t("companies.typesOptions.mitigation"),
          value: "Mitigation",
        },
        {
          text: this.$t("companies.typesOptions.remodeling"),
          value: "Remodeling",
        },
        {
          text: this.$t("companies.typesOptions.other"),
          value: "Other",
        },
      ];
    },
    sellersOptions() {
      return [
        {
          text: this.$t("companies.sellersOptions['1-5']"),
          value: "1-5",
        },
        {
          text: this.$t("companies.sellersOptions['6-15']"),
          value: "6-15",
        },
        {
          text: this.$t("companies.sellersOptions['15+']"),
          value: "15+",
        },
      ];
    },
    filteredItems() {
      return this.desserts.filter((item) => {
        const searchLower = this.search.toLowerCase();
        return (
          item.name.toLowerCase().includes(searchLower) ||
          item.email.toLowerCase().includes(searchLower) ||
          item.address.toLowerCase().includes(searchLower) ||
          item.phone_number.toLowerCase().includes(searchLower) ||
          item.created_at.toLowerCase().includes(searchLower) ||
          this.$t(`companies.typesOptions.${item.company_type.toLowerCase()}`)
            .toLowerCase()
            .includes(searchLower) ||
          item.sellers.toLowerCase().includes(searchLower)
        );
      });
    },
  },
  methods: {
    async getCompanies() {
      this.loading = true;
      try {
        const response = await companies.fetchCompanies();
        if (response.code == 200) {
          return response.data.companies.map((member) => ({
            ...member,
            created_at: moment(member.created_at).format("MM / DD / YYYY"),
          }));
        } else {
          return [];
        }
      } catch (error) {
        return [];
      } finally {
        this.loading = false;
      }
    },
    async createCompany() {
      try {
        this.loading = true;
        this.error = false;
        const data = {
          name: this.newCompany.name,
          address: this.newCompany.address,
          phone_number: this.newCompany.phone_number,
          email: this.newCompany.email,
          company_type: this.newCompany.type,
          sellers: this.newCompany.seller,
          notes: this.newCompany.notes,
          company_id: this.newCompany.company_id,
        };

        let response;

        if (this.newCompany.company_id) {
          response = await companies.updateCompany(data);
        } else {
          response = await companies.createCompany(data);
        }
        if (response.code == 200) {
          this.resetDialog();
          this.dialog = false;
          this.desserts = await this.getCompanies();
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    openDialog() {
      this.resetDialog();
      this.dialog = true;
    },
    editCompany(item) {
      this.dialog = true;
      this.newCompany.name = item.name;
      this.newCompany.address = item.address;
      this.newCompany.phone_number = item.phone_number;
      this.newCompany.email = item.email;
      this.newCompany.company_id = item.id;
      this.newCompany.type = item.company_type;
      this.newCompany.seller = item.sellers;
      this.newCompany.notes = item.notes;
    },
    setInitialCompany() {
      this.newCompany.type = this.newCompany.types[0];
      this.newCompany.seller = this.newCompany.sellers[0];
    },
    resetDialog() {
      this.newCompany = {
        name: "",
        address: "",
        phone_number: "",
        email: "",
        notes: "",
        types: ["Roofing", "Mitigation", "Remodeling", "Other"],
        sellers: ["1-5", "6-15", "15+"],
        company_id: "",
      };
      this.newCompany.type = this.newCompany.types[0];
      this.newCompany.seller = this.newCompany.sellers[0];
    },
  },
  async activated() {
    this.desserts = await this.getCompanies();
    this.setInitialCompany();
  },
};
</script>

<style scoped>
::v-deep table tbody tr td {
  padding: 20px 16px !important;
}

@media (min-width: 768px) {
  ::v-deep table tbody tr td:first-child,
  ::v-deep table thead tr th:first-child {
    padding-left: 3rem !important;
  }
  ::v-deep table tbody tr td:last-child,
  ::v-deep table thead tr th:last-child {
    padding-right: 3rem !important;
  }

  ::v-deep table tbody tr td {
    border: none !important;
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep table thead tr th {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 30px !important;
}
</style>
